<template>
    <section class="main">
        <popup-item
            v-if="isInfoPopupVisible"
            @closePopup="closeInfoPopup"
        />
        <div class="wrapper">
            <div class="main__content">
                <h1 class="main__title">{{ $t('main_title') }}</h1>
                <h2 class="main__subtitle">{{ $t('main_subtitle') }}</h2>
                <div class="line"></div>
                <h2 class="main__text">{{ $t('main_text') }}</h2>
                <button class="main__button btn" @click="showPopupInfo">{{ $t('main_button') }}</button>
            </div>
        </div>
    </section>
</template>
<script> 
    import PopupItem from '../../PopupItem.vue';
    export default {   
        name: 'MainSection',
        components: {
            PopupItem
        },
        data() {
            return {
                isInfoPopupVisible: false
            }
        },
        methods: {
            showPopupInfo() {
                this.isInfoPopupVisible = true;                
                document.body.classList.add('modal-open');
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;                
                document.body.classList.remove('modal-open');
            }
        }
    }
</script>
<style lang="scss">
    .main {
        background-image: url(../../../assets/images/bgimg/index.png);
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        @media (max-width: 600px) {
            height: 100%;
        }
        @media (max-width: 500px) {
            background-attachment: scroll;
        }
        &__content {            
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 560px;            
            color: #ffffff;
            @media (max-width: 600px) {
                align-items: center;
                text-align: center;
                width: 100%;
                padding-bottom: 100px;
            }
        }
        &__title {
            font-size: 50px;
            font-weight: 800;
            padding: 100px 0 20px;
            line-height: 1.1;
            @media (max-width: 600px) {
                font-size: 35px;
                padding: 185px 0 45px;
            }
        }
        &__subtitle {
            font-size: 20px;
            line-height: 1.5;        
        }
        &__text {
            font-weight: 300;
            font-size: 17px;
            padding-bottom: 40px;
            @media (max-width: 600px) {
                padding-bottom: 35px;
            }
        }
        &__button {
            font-size: 15px;
            font-weight: 600;
        }
    }
</style>