<template>
    <section class="payments-info">
        <div class="payments-info__wrapper wrapper">
            <h2 class="payments-info__title">{{ $t('payments_info_title') }}</h2>
            <div class="line"></div>
            <div class="payments-info__items">
                <div class="payments-info__img-wrap">
                    <img class="payments-info__img" src="../../../assets/images/i7.png" alt="img">
                </div>  
                <div class="payments-info__content">                    
                    <p class="payments-info__text">{{ $t('payments_info_text_1') }}</p>
                    <p class="payments-info__text">{{ $t('payments_info_text_2') }}</p>
                    <p class="payments-info__text">{{ $t('payments_info_text_3') }}</p> 
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {    
        name: 'PaymentsInfoSection'
    }
</script>
<style lang="scss">
    .payments-info {
        background-image: url(../../../assets/images/bgimg/bg2.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;  
        background-attachment: fixed;      
        color:#fff;
        padding: 110px 0;
        @media (max-width: 990px) {
            padding-bottom: 80px;   
        }
        @media (max-width: 500px) {
            background-attachment: scroll;    
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 26px;               
            } 
        }
        &__items {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 40px;
            gap: 90px;
            width: 100%;
            @media (max-width: 990px) {
               flex-direction: column; 
               align-items: center;  
               gap: 0;   
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            gap: 40px;
            @media (max-width: 990px) {
                width: 100%; 
            }
        }
        &__text {
            font-size: 17px;
            font-weight: 700;
        }
        &__img-wrap {
            width: 50%;
            position: relative;
            @media (max-width: 600px) {
                width: 80%;    
            }
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
</style>