<template>
    <section class="info">
        <popup-item
            v-if="isInfoPopupVisible"
            @closePopup="closeInfoPopup"
        />
        <div class="info__wrapper wrapper">
            <div class="line"></div>
            <h2 class="info__title">{{ $t('info_title') }}</h2>
            <p class="info__subtitle">{{ $t('info_subtitle') }}</p>
            <button class="info__button btn" @click="showPopupInfo">{{ $t('info_button') }}</button>
        </div>
    </section>
</template>
<script>
    import PopupItem from '../../PopupItem.vue';
    export default {    
        name: 'InfoSection',
        components: {
            PopupItem
        },
        data() {
            return {
                isInfoPopupVisible: false
            }
        },
        methods: {
            showPopupInfo() {
                this.isInfoPopupVisible = true;                
                document.body.classList.add('modal-open');
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;                
                document.body.classList.remove('modal-open');
            }
        }
    }
</script>
<style lang="scss">
    .info {
        background-color: #03031c;
        padding: 110px 0;
        color: #fff;
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 27px;
            font-weight: 700;
            text-align: center;
            padding: 45px 0;
            max-width: 680px;
            @media (max-width: 600px) {
                padding: 45px 0 80px;            
            }
        }
        &__subtitle {
            font-size: 17px;
            text-align: center;
        }
        &__button {
            margin-top: 40px;               
            font-size: 15px;
            font-weight: 500;
        }
    }
</style>