<template>           
    <div>         
        <div :class="{ show: showScrollButton }" class="scroll-to-top" @click="scrollToTop" v-if="showScrollButton" id="scrollup">
            <span class="icon-keyboard_arrow_up"></span>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, onUnmounted } from 'vue';
    export default {    
        name: 'ScrollButton',       
        setup() {
            const showScrollButton = ref(false);
            const checkScrollPosition = () => {
                if (
                    document.body.scrollTop > 20 ||
                    document.documentElement.scrollTop > 20
                ) {
                    showScrollButton.value = true;
                } else {
                    showScrollButton.value = false;
                }
            };
            const scrollToTop = () => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            };

            onMounted(() => {
                window.addEventListener('scroll', checkScrollPosition);
            });

            onUnmounted(() => {
                window.removeEventListener('scroll', checkScrollPosition);
            });

            return {
                showScrollButton,
                scrollToTop
            };
        }
    }
</script>

<style lang="scss">
.scroll-to-top {
    position: fixed;
    top: calc(100vh - 90px);
    right: 30px;
    z-index: 999;
    width: 44px;
    height: 44px;
    background-color: rgba(64,226,153, 0.9);
    color: #fff;
    border-radius: 5px;
    font-size: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    cursor: pointer;
    @media (max-width:425px) {
        right: 10px;
        top: calc(100vh - 60px);
    }
    &:hover {
        background-color: #39cbd7;
    }
    &.show {
        opacity: 1;
        transition: opacity 1s ease-in-out;
    }
}
</style>