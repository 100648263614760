<template>
    <article class="payments-article">
        <payments-main-section/>
        <payments-info-section/>
        <payments-advantage-section/>
        <payments-guide-section/>
        <payments-process-section/>
        <contacts-section/>
        <scroll-button/>
    </article>
</template>
<script>
    import PaymentsMainSection from './AcceptPaymentsItems/PaymentsMainSection.vue';
    import PaymentsInfoSection from './AcceptPaymentsItems/PaymentsInfoSection.vue';
    import PaymentsAdvantageSection from './AcceptPaymentsItems/PaymentsAdvantageSection.vue';
    import PaymentsGuideSection from './AcceptPaymentsItems/PaymentsGuideSection.vue';
    import PaymentsProcessSection from './AcceptPaymentsItems/PaymentsProcessSection.vue';
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue'
    export default {    
        name: 'AcceptPayments',
        components: {
            PaymentsMainSection,
            PaymentsInfoSection,
            PaymentsAdvantageSection,
            PaymentsGuideSection,
            PaymentsProcessSection,
            ContactsSection,
            ScrollButton
        }
    }
</script>
<style lang="scss">
    .payments-article {
        position: relative;
    }
</style>