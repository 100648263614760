<template>
    <section class="payments-guide">
        <popup-item
            v-if="isInfoPopupVisible"
            @closePopup="closeInfoPopup"
        />
        <div class="payments-guide__wrapper wrapper">
            <h2 class="payments-guide__title">{{ $t('payments_guide_title') }}</h2>
            <div class="line"></div>
            <div class="payments-guide__items">
                <div class="payments-guide__content">
                    <div class="payments-guide__sq">
                        <div class="payments-guide__sq1">
                            <div class="payments-guide__sq2">
                                1
                            </div>
                        </div>
                    </div>
                    <p class="payments-guide__text">{{ $t('payments_guide_text_1') }}</p>
                </div>
                <div class="payments-guide__content">
                    <div class="payments-guide__sq">
                        <div class="payments-guide__sq1">
                            <div class="payments-guide__sq2">
                                2
                            </div>
                        </div>
                    </div>
                    <p class="payments-guide__text">{{ $t('payments_guide_text_2') }}</p>
                </div>
                <div class="payments-guide__content">
                    <div class="payments-guide__sq">
                        <div class="payments-guide__sq1">
                            <div class="payments-guide__sq2">
                                3
                            </div>
                        </div>
                    </div>
                    <p class="payments-guide__text">{{ $t('payments_guide_text_3') }}</p>
                </div>
            </div>
            <button class="exchange__button btn" @click="showPopupInfo">{{ $t('payments_guide_button') }}</button>
        </div>
    </section>
</template>
<script>
    import PopupItem from '../../PopupItem.vue';
    export default {    
        name: 'PaymentsGuideSection',
        components: {
            PopupItem
        },
        data() {
            return {
                isInfoPopupVisible: false
            }
        },
        methods: {
            showPopupInfo() {
                this.isInfoPopupVisible = true;                
                document.body.classList.add('modal-open');
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;                
                document.body.classList.remove('modal-open');
            }
        }
    }
</script>
<style lang="scss">
    .payments-guide {
        background-color: #03031c;       
        color:#fff;
        padding: 110px 0;
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 26px;       
            }
        }
        &__items {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            @media (max-width: 650px) {
                flex-direction: column;
                align-items: center;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            padding: 40px 0 50px;
            width: 30%;
            @media (max-width: 650px) {
                width: 80%;
                gap: 40px;
                padding: 15px 0 40px;
            }
        }
        &__text {
            text-align: center;
            font-size: 17px;
            font-weight: 600;
        }
        &__sq {
            background-image: linear-gradient(-90deg, #6067fd 0%, #fdcef2 100%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__sq1 {
            width: 74px;
            height: 74px;
            border-radius: 50%;
            background-color: #0a0a21; 
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__sq2 {
            background-color: #3a3b50;
            width: 60px;
            height: 60px;
            border-radius: 50%; 
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 21px;
            font-weight: 600;
        }
    }
</style>