<template>
    <section class="exchange-advantages">
        <div class="exchange-advantages__wrapper wrapper">
            <h2 class="exchange-advantages__title">{{ $t('exchange_advantage_title') }}</h2>
            <div class="line"></div>
            <div class="exchange-advantages__content">
                <div class="exchange-advantages__img-wrap">
                    <img class="exchange-advantages__img" src="../../../assets/images/i14.png" alt="img">
                </div>  
                <div class="exchange-advantages__list">
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_1') }}</p>
                    </div>
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_2') }}</p>
                    </div>
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_3') }}</p>
                    </div>
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_4') }}</p>
                    </div> 
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_5') }}</p>
                    </div>
                    <div class="exchange-advantages__list-item">
                        <span class="icon-check exchange-advantages__icon"></span>
                        <p class="exchange-advantages__text">{{ $t('exchange_advantage_text_6') }}</p>
                    </div>  
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {    
        name: 'ExchangeAdvantagesSection'
    }
</script>
<style lang="scss">
    .exchange-advantages {
        background-image: url(../../../assets/images/bgimg/bg5.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;     
        background-attachment: fixed;   
        color:#fff;
        padding: 110px 0;
        @media (max-width: 990px) {
            padding-bottom: 80px;   
        }
        @media (max-width: 500px) {
            background-attachment: scroll;
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 26px;               
            }
        }
        &__content {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            padding-top: 40px;
            gap: 90px;
            width: 100%;
            @media (max-width: 990px) {
                flex-direction: column;   
                gap: 50px;   
            }
        }
        &__list {
            max-width: 400px;
            @media (max-width: 990px) {
                max-width: 100%;    
            }
        }
        &__list-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 40px;
            gap: 10px;
            font-size: 21px;
            font-weight: 600;
            @media (max-width: 990px) {
                padding-bottom: 30px;  
            }
            @media (max-width: 600px) {
                font-size: 17px;             
            }
        }
        &__icon {
            color: #40e299;
        }
        &__img-wrap {
            width: 330px;
            height: 340px;
            position: relative;
            margin-bottom: 30px;
            margin-right: 100px;
            @media (max-width: 990px) {
                margin: 0;    
            }
            @media (max-width: 600px) {
                width: 245px;
                height: 250px;             
            }
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
</style>