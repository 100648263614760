<template>
    <article class="exchange-article">
        <exchange-main-section/>
        <exchange-info-section/>
        <exchange-advantage-section/>
        <exchange-ability-section/>
        <contacts-section/>
        <scroll-button/>
    </article>
</template>
<script>
    import ExchangeMainSection from './CryptocurrencyExchangeItems/ExchangeMainSection.vue';
    import ExchangeInfoSection from './CryptocurrencyExchangeItems/ExchangeInfoSection.vue';
    import ExchangeAdvantageSection from './CryptocurrencyExchangeItems/ExchangeAdvantageSection.vue';
    import ExchangeAbilitySection from './CryptocurrencyExchangeItems/ExchangeAbilitySection.vue';
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue'
    export default {    
        name: 'CryptocurrencyExchange',
        components: {
            ExchangeMainSection,
            ExchangeInfoSection,
            ExchangeAdvantageSection,
            ExchangeAbilitySection,
            ContactsSection,
            ScrollButton
        }
    }
</script>
<style lang="scss">
    .exchange-article {
        position: relative;
    }
</style>