<template>
    <section class="services">
        <div class="wrapper">
            <div class="services__content">
                <h2 class="services__title">{{ $t('services_title') }}</h2>
                <div class="line"></div>
                <div class="services__items">
                    <div class="services__item">
                        <div class="services__img-wrap">
                            <img class="services__img" src="../../../assets/images/i2.png" alt="img">
                        </div>
                        <h3 class="services__subtitle">{{ $t('services_subtitle_1') }}</h3>
                        <p class="services__text">{{ $t('services_text_1') }}</p>
                        <router-link class="services__button btn" to="/cryptocurrency-exchange">{{ $t('services_button') }}</router-link>
                    </div>
                    <div class="services__item">
                        <div class="services__img-wrap">
                            <img class="services__img" src="../../../assets/images/i3.png" alt="img">
                        </div>
                        <h3 class="services__subtitle">{{ $t('services_subtitle_2') }}</h3>
                        <p class="services__text">{{ $t('services_text_2') }}</p>
                        <router-link class="services__button btn" to="/accepting-payments-in-cryptocurrency">{{ $t('services_button') }}</router-link>
                    </div>
                    <div class="services__item">
                        <div class="services__img-wrap">
                            <img class="services__img" src="../../../assets/images/i1.png" alt="img">
                        </div>
                        <h3 class="services__subtitle">{{ $t('services_subtitle_3') }}</h3>
                        <p class="services__text">{{ $t('services_text_3') }}</p>
                        <a class="services__button btn" href="https://ca.stablexsolution.com/login">{{ $t('services_button') }}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {    
        name: 'ServicesSection'
    }
</script>
<style lang="scss">
    .services {
        background-color: #03031c;
        padding: 110px 0;
        color: #fff;
        &__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;            
            justify-content: center;
        }
        &__items {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 75px;
            flex-wrap: wrap;
            gap: 20px;
            @media (max-width: 768px) {
                padding-top: 45px;                
            }
            @media (max-width: 600px) {
                padding-top: 35px; 
                gap: 45px;              
            }
        }
        &__item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 25%;
            max-width: 260px;
            min-height: 370px;
            @media (max-width: 760px) {
                flex-basis: 100%;               
            }
        }
        &__title {
            font-size: 34px;
            font-weight: 700;   
            @media (max-width: 600px) {
                font-size: 26px;               
            }         
        }
        &__subtitle {
            font-size: 24px;
            font-weight: 700;
            padding-bottom: 10px;            
            text-align: center;
            width: 100%;
        }
        &__text {
            text-align: center;
            max-width: 210px;
            font-weight: 600;
        }
        &__img-wrap {
            width: 150px;
            height: 150px;
            position: relative;
            margin-bottom: 30px;
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &__button {
            margin-top: 25px;
            font-size: 15px;
            font-weight: 600;
        }
    }
</style>