<template>           
    <section class="exchange">
        <popup-item
            v-if="isInfoPopupVisible"
            @closePopup="closeInfoPopup"
        />
        <div class="wrapper">
            <div class="exchange__content">
                <h1 class="exchange__title">{{ $t('exchange_title') }}</h1>
                <div class="line"></div>
                <h2 class="exchange__subtitle">{{ $t('exchange_subtitle') }}</h2>
                <button class="exchange__button btn" @click="showPopupInfo">{{ $t('exchange_button') }}</button>
            </div>
        </div>
    </section>
</template>

<script>
    import PopupItem from '../../PopupItem.vue';
    export default {    
        name: 'ExchangeMainSection',
        components: {
            PopupItem
        },
        data() {
            return {
                isInfoPopupVisible: false
            }
        },
        methods: {
            showPopupInfo() {
                this.isInfoPopupVisible = true;                
                document.body.classList.add('modal-open');
            },
            closeInfoPopup() {
                this.isInfoPopupVisible = false;                
                document.body.classList.remove('modal-open');
            }
        }        
    }
</script>

<style lang="scss">
    .exchange {
        background-image: url(../../../assets/images/bgimg/cryptocurrency-exchange.png);
        height: 100vh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        @media (max-width: 600px) {
            height: 100%;
        }
        @media (max-width: 500px) {
            background-attachment: scroll;
        }
        &__content {            
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100%;
            width: 560px;            
            color: #ffffff;
            @media (max-width: 600px) {
                align-items: center;
                text-align: center;
                width: 100%;
                padding-bottom: 100px;
            }
        }
        &__title {
            font-size: 50px;
            font-weight: 800;
            padding: 100px 0 20px;
            line-height: 1.1;
            @media (max-width: 600px) {
                font-size: 35px;
                padding: 185px 0 45px;
            }
        }
        &__subtitle {
            font-size: 20px;
            line-height: 1.5;   
            padding-bottom: 50px;     
        }
        &__button {
            font-size: 15px;
            font-weight: 600;
        }
    }
</style>