<template>
    <section class="contacts">
        <div class="contacts__wrapper wrapper">
            <h2 class="contacts__title">{{ $t('contacts_title') }}</h2>
            <div class="line"></div>
            <div class="contacts__content">
                <login-form/>     
                <div class="contacts__info">
                    <p class="contacts__subtitle">{{ $t('contacts_subtitle') }}</p>
                    <div class="contacts__content-item">
                        <span class="icon-check contacts__icon"></span>
                        <span class="contacts__text">{{ $t('contacts_tel') }}&nbsp;</span>
                        <a class="contacts__link" href="tel:+380933335018">+38 (093) 333-50-18</a>
                    </div>
                    <div class="contacts__content-item">
                        <span class="icon-check contacts__icon"></span>
                        <span class="contacts__text">{{ $t('contacts_mail') }}&nbsp;</span>
                        <a class="contacts__link" href="mailto:info@solvexs.pl">info@solvexs.pl</a>
                    </div>
                    <div class="contacts__content-item">
                        <span class="icon-check contacts__icon"></span>
                        <span class="contacts__text">{{ $t('contacts_address') }}&nbsp;</span>
                        <a class="contacts__link" href="https://goo.gl/maps/shfuw153c1jdcBh1A" target="_blank" rel="noopener">Poland,
                            Warsaw, 86&nbsp;HOŹA&nbsp;street, unit&nbsp;210, city/town:&nbsp;WARSAW, post&#8209;code:&nbsp;00&#8209;682.
                        </a>
                    </div>  
                </div>               
            </div>
        </div>
    </section>
</template>
<script>
    import LoginForm from "./LoginForm.vue";
    export default {    
        name: 'ContactsSection',
        components: {
            LoginForm
        }
    }
</script>
<style lang="scss">
    .contacts {
        background-color: #0a0a21;       
        color:#fff;
        padding: 110px 0;
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 26px;       
            }
        }
        &__subtitle {
            font-size: 17px;
            padding-bottom: 70px;
            @media (max-width: 600px) {
                padding-bottom: 50px;      
            }
        }
        &__content {
            display: flex;
            flex-direction: row-reverse;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 40px;
            gap: 30px;
            width: 100%;
            @media (max-width: 700px) {
                flex-direction: column-reverse;   
                align-items: center;            
            }
        }
        &__content-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 20px;
            gap: 10px;
            font-size: 17px;
            font-weight: 600;            
            line-height: 1.5;
        }
        &__icon {
            font-size: 21px;
            color: #40e299;
        }
        &__link {
            text-decoration: none;
            color: #6d6d90;
        }
    }
</style>