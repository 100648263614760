<template>
  <header class="header" ref="header">
    <div class="header__wrapper wrapper">
      <router-link to="/">
        <img class="header__logo" src="../assets/images/logo/stablex1_d.svg" alt="Логотип">
      </router-link>
      <nav class="header__menu">
        <ul class="header__menu-items">
          <li class="header__menu-item">
            <span class="header__link">{{ $t('header_item_1') }}<span class="icon-arrow_drop_down"></span></span>
            <ul class="header__submenu">
              <li><router-link class="header__link" to="/cryptocurrency-exchange">{{ $t('header_item_1_1') }}</router-link></li>
              <li><router-link class="header__link" to="/accepting-payments-in-cryptocurrency">{{ $t('header_item_1_2') }}</router-link></li>
            </ul>
          </li>
          <li class="header__menu-item">
            <router-link class="header__link" to="/stablex-solution">{{ $t('header_item_2') }}</router-link>          
          </li>
          <li class="header__menu-item"><router-link class="header__link" to="/guide-to-crypto">{{ $t('header_item_3') }}</router-link></li>
          <li class="header__menu-item"><router-link class="header__link" to="/contacts">{{ $t('header_item_4') }}</router-link></li>
          <li class="header__menu-item"><a class="header__link" href="https://ca.stablexsolution.com/login">{{ $t('header_item_5') }}</a></li>
          <li class="header__menu-item"><a class="header__button btn" href="https://ca.stablexsolution.com/register">{{ $t('header_item_6') }}</a></li>
        </ul>
      </nav>
      <div class="header__menu-item header__menu-small">
        <div class="header__lang">{{ $i18n.locale }}</div>
        <ul class="header__submenu header__submenu--special">
          <span class="header__link" @click="setLocale('ru')">RU</span>
          <span class="header__link" @click="setLocale('en')">EN</span>
          <span class="header__link" @click="setLocale('pl')">PL</span>
        </ul>
        <div class="header__burger" :class="{ 'open': isOpen }" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>        
      </div>      
      <nav class="header__menu-tablet" :class="{ 'open': isOpen }" >
        <ul class="wrapper header__tablet-items">
          <li class="header__menu-item">
            <span class="header__link">{{ $t('header_item_1') }}<span class="icon-arrow_drop_down"></span></span>
            <ul class="header__submenu">
              <li><router-link class="header__link" to="/cryptocurrency-exchange" v-on:click="closeHeader()">{{ $t('header_item_1_1') }}</router-link></li>
              <li><router-link class="header__link" to="/accepting-payments-in-cryptocurrency" v-on:click="closeHeader()">{{ $t('header_item_1_2') }}</router-link></li>
            </ul>
          </li>
          <li class="header__menu-item">
            <router-link class="header__link" to="/stablex-solution" v-on:click="closeHeader()">{{ $t('header_item_2') }}</router-link>          
          </li>
          <li class="header__menu-item"><router-link class="header__link" to="/guide-to-crypto" v-on:click="closeHeader()">{{ $t('header_item_3') }}</router-link></li>
          <li class="header__menu-item"><router-link class="header__link" to="/contacts" v-on:click="closeHeader()">{{ $t('header_item_4') }}</router-link></li>
          <li class="header__menu-item"><a class="header__link" href="https://ca.stablexsolution.com/login">{{ $t('header_item_5') }}</a></li>
          <li class="header__menu-item"><a class="header__button btn" href="https://ca.stablexsolution.com/register">{{ $t('header_item_6') }}</a></li>
        </ul>
        <ul class="wrapper header__mobile-items">
          <li class="header__menu-item">
            <span class="header__link">{{ $t('header_item_1') }}</span>
            <ul class="header__submenu-mobile">
              <li><router-link class="header__link header__link-mobile" to="/cryptocurrency-exchange" >{{ $t('header_item_1_1') }}</router-link></li>
              <li><router-link class="header__link header__link-mobile" to="/accepting-payments-in-cryptocurrency">{{ $t('header_item_1_2') }}</router-link></li>
            </ul>
          </li>
          <li class="header__menu-item">
            <router-link class="header__link" to="/stablex-solution">{{ $t('header_item_2') }}</router-link>          
          </li>
          <li class="header__menu-item"><router-link class="header__link" to="/guide-to-crypto">{{ $t('header_item_3') }}</router-link></li>
          <li class="header__menu-item"><router-link class="header__link" to="/contacts">{{ $t('header_item_4') }}</router-link></li>
          <li class="header__menu-item"><a class="header__link" href="https://ca.stablexsolution.com/login">{{ $t('header_item_5') }}</a></li>
          <li class="header__menu-item"><a class="header__button btn" href="https://ca.stablexsolution.com/register">{{ $t('header_item_6') }}</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  beforeRouteLeave (to, from, next) {
    this.isOpen = false
    next()
  },
  name: 'HeaderItem',
  data() {
    return {
      isOpen: false,
      locale: 'ru',
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
    },
    closeMenu(event) {
      if (!this.$refs.header.contains(event.target)) {
        this.isOpen = false; 
      }
    },
    closeHeader() {
    this.$refs.header.classList.remove('open');
    this.isOpen = false;
  },
  },
  mounted() {
    const menuItems = document.querySelectorAll(".header__menu-item");
    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", () => {
        const activeSubmenus = document.querySelectorAll(".header__submenu.active");
        activeSubmenus.forEach((activeSubmenu) => {
          if (!menuItem.contains(activeSubmenu)) {
            activeSubmenu.classList.remove("active");
          }
        });
        const submenu = menuItem.querySelector(".header__submenu");
        if (submenu) {
          submenu.classList.toggle("active");
        }
      });
    });

    window.addEventListener("click", (event) => {
      const openSubmenus = document.querySelectorAll(".header__submenu.active");
      openSubmenus.forEach((submenu) => {
        if (!submenu.contains(event.target) && !submenu.previousElementSibling.contains(event.target)) {
          submenu.classList.remove("active");
        }
      });
    });
    document.addEventListener('click', this.closeMenu);
  },
  unmounted() {
    document.removeEventListener('click', this.closeMenu);
  },
};
</script>
<style lang="scss">
  .header {
    font-family: "Gilroy-Bold", sans-serif;
    font-weight: 800;
    font-size: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    color: #1b1b30;
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
    }
    &__logo {
      width: 153px;
      height: 53px;
    }
    &__menu {
      @media (max-width: 990px) {
        display: none;
      }
      @media (min-width: 1440px) {
        min-width: 810px;
      }
      @media (min-width: 1960px) {
        min-width: 910px;
      }
    }
    &__menu-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 20px;  
      min-width: 710px;      
    }
    &__menu-item {
      position: relative;
      list-style: none;
      cursor: pointer;
    }
    &__submenu {
      background-color: #fff;
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      display: block;
      list-style: none;
      margin: 0;
      padding: 15px;
      position: absolute;
      top: 130%;
      left: 0;
      z-index: 1;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
      &.active {
        display: block;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out, visibility 0s linear 0s;
      }
      & li {
        line-height: 1.5;
      }
      &--special {
        left: -50%;
        @media (max-width: 990px) {
          left: -20%;
        }
      }
    }
    &__link {
      text-decoration: none;
      color: #1b1b30;
      white-space: nowrap;
      @media (max-width: 690px) {
        line-height: 1.5;
      }     
      &:hover {
      opacity: 0.8;
        @media (max-width: 690px) {
          color: #39cbd7;
          opacity: 1;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background-color: #1b1b30;
        transition: width 0.3s ease-in-out;
      }      
      &:hover::after {
        width: 100%;
        opacity: 0.8;
        @media (max-width: 690px) {
          width: 0;
        }
      }
    }
    &__button {
      &:hover {  
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      }
      &.btn {     
        @media (max-width: 690px) {
          margin-top: 20px;
        } 
      }
    }
    &__lang {
      text-transform: uppercase;
      width: 25px;
      &:hover {        
        opacity: 0.7;
      }
    }
    &__burger {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 25px;
      height: 15px;
      cursor: pointer;
      @media (min-width: 991px) {
        display: none;
      }
      @media (max-width: 990px) {
        display: flex;
      }
      & span {
        width: 25px;
        height: 2px;
        background-color: #1b1b30;
        display: block;
        transition: all 0.3s ease-in-out;
      }
      &.open span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      &.open span:nth-child(2) {
        transform: rotate(-45deg) translate(4px, -4px);
      }
      &.open span:nth-child(3) {
        display: none;
      }
    }
    &__menu-tablet {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 100%;
      right: 0;
      height: 0;
      transition: all 0.3s ease-in-out;
      width: 100%;
      background-color: #fff;
      color: #1b1b30;
      opacity: 0;
      overflow: hidden;
      border-top: 1px solid #ddd;      
      border-bottom: 1px solid #ddd;
    @media (min-width: 991px) {
      display: none;
    }
    &.open {
      right: 0;
      height: auto;
      transition: all 0.3s ease-in-out;
      opacity: 1;
      overflow: visible;
    }      
  }
  &__tablet-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 690px) {
      display: none;
    }
  }
  &__mobile-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0;
    @media (min-width: 691px) {
      display: none;
    }
  }
  &__submenu-mobile {
    list-style: none;    
  }
  &__link-mobile {
    font-family: 'Gilroy-Light', sans-serif;   
    padding-left: 10px;
  }
  &__menu-small {
    @media (max-width: 990px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
}
</style>