<template>
    <article class="contacts-article">
        <contacts-main-section/>
        <contacts-section/>
        <scroll-button/>
    </article>
</template>
<script>
    import ContactsMainSection from './ContactsPageItems/ContactsMainSection.vue';
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue';
    export default {    
        name: 'ContactsPage',
        components: {
            ContactsMainSection,
            ContactsSection,
            ScrollButton
        }
    }
</script>
<style lang="scss">
    .contacts-article {
        position: relative;
    }
</style>