import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    locale: 'ru', 
    fallbackLocale: 'ru',
    messages: {
      en: {
        header_item_1: 'For you',
        header_item_1_1: 'Cryptocurrency exchange',
        header_item_1_2: 'Accepting payments in cryptocurrency',
        header_item_2: 'About company',
        header_item_3: 'Info',
        header_item_4: 'Contact us',
        header_item_5: 'Sign in',
        header_item_6: 'Register',
        main_title: 'Stablex Solution - a trusted cryptocurrency exchange partner',
        main_subtitle: 'Modern financial services based on digital financial assets for businesses and individuals',
        main_text: 'Stablex Solution helps improve the financial system by applying modern digital products and assets',
        main_button: 'Contact us',
        services_title: 'Our services',
        services_subtitle_1: 'Exchange',
        services_subtitle_2: 'Payment Gateway',
        services_subtitle_3: 'Asset Management',
        services_text_1: 'Exchanging cryptocurrencies for cash or credit card',
        services_text_2: 'Accepting online payments in cryptocurrency',
        services_text_3: 'An alternative way to make money by entrusting crypto-assets for management',
        services_button: 'Learn more',
        financial_title: 'Why a digital financial system is better than classic fiat',
        financial_text_1: 'low commissions;',
        financial_text_2: 'there are no intermediaries;',
        financial_text_3: 'high transaction speed;',
        financial_text_4: 'full confidentiality, transparency and security for all participants.',
        info_title: 'Stablex Solution is a convenient, fast and reliable service for safe use of modern digital technology.',
        info_subtitle: 'Start using all the features of Stablex Solution today!',
        info_button: 'Leave a request',
        advantage_title: 'Why customers choose Stablex Solution',
        advantage_text_1: 'can work with all popular cryptocurrencies;',
        advantage_text_2: 'ensure full client confidentiality and data protection;',
        advantage_text_3: 'we offer favourable rates with minimal transaction fees;',
        advantage_text_4: 'we provide services in compliance with legal requirements and provide documents to support the transaction.',
        contacts_title: 'Do you still have questions?',
        contacts_subtitle: 'Get detailed advice from our specialists.',
        contacts_tel: 'Phone:',
        contacts_mail: 'Email: ',
        contacts_address: 'Address:',
        login_name: 'Your name',
        login_tel: 'Phone',
        login_mail: 'Email',
        login_message: 'Message',
        login_button: 'Send',
        footer_item_1: 'For you',
        footer_item_1_1: 'Cryptocurrency exchange',
        footer_item_1_2: 'Accepting payments in cryptocurrency',
        footer_item_2: 'COMPANY',
        footer_item_2_1: 'About Stablex Solution',
        footer_item_2_2: 'AML',
        footer_item_3: 'INFORMATION',
        footer_item_3_1: 'Crypto Guides',
        exchange_title: 'Cryptocurrency exchange',
        exchange_subtitle: 'cryptocurrency exchange for cash or credit card for individuals and companies',
        exchange_button: 'Leave request',
        exchange_info_title: 'About service',
        exchange_info_text_1: 'Stablex Solution is an online platform that provides the customer with the ability to exchange fiat money into cryptocurrency and vice versa.',
        exchange_info_text_2: 'Individual customers can buy or sell cryptocurrencies with cash or a credit card. Customers-legal entities make the exchange via non-cash payment.',
        exchange_info_text_3: 'The calculation is performed:',
        exchange_info_text_4: 'for fiat money;',
        exchange_info_text_5: 'through a bank transfer;',
        exchange_info_text_6: 'via credit card payment.',
        exchange_advantage_title: 'Why buy and sell cryptocurrency from us',
        exchange_advantage_text_1: 'high liquidity;',
        exchange_advantage_text_2: 'minimal commissions;',
        exchange_advantage_text_3: 'different payment methods are available;',
        exchange_advantage_text_4: 'guaranteed security and confidentiality of transactions;',
        exchange_advantage_text_5: 'technical customer support;',
        exchange_advantage_text_6: 'the service is accessible from anywhere in the world.',
        exchange_ability_title: 'With the Stablex Solution you can',
        exchange_ability_text_1: 'Avoid being blocked by banks, which may not allow a digital payment to pass through and may freeze or confiscate funds',
        exchange_ability_text_2: 'Conduct the transaction with full security by seeing and communicating with the other party in person as well as remotely',
        exchange_ability_text_3: 'Avoid being blocked by banks, which may not allow a digital payment to pass through and may freeze or confiscate funds',
        exchange_ability_button: 'Send request',
        payments_title: 'Accepting payments in cryptocurrency',
        payments_subtitle: 'accepting online payments in cryptocurrency - a service for commercial websites',
        payments_button: 'Leave request',
        payments_info_title: 'About service',
        payments_info_text_1: 'It is an online service that processes payments between a buyer and a merchant - an online shop. Thus, this platform helps commercial websites accept online payments from buyers in cryptocurrency. To use the service, the merchant must register on the platform and connect the software to receive payments.',
        payments_info_text_2: 'Our platform greatly simplifies the process of making online payments in cryptocurrency for buyers and sellers and promotes the use of cryptocurrency as a means of payment for goods provided.',
        payments_info_text_3: 'All payments are made with transparent and complete statistics on all transactions. Funds are available for withdrawal as soon as they are received.',
        payments_advantage_title: 'Advantages',
        payments_advantage_text_1: 'fast connection',
        payments_advantage_text_2: 'minimum fee',
        payments_advantage_text_3: 'user-friendly interface',
        payments_guide_title: 'Connection steps',
        payments_guide_text_1: 'filling in an application for connection',
        payments_guide_text_2: 'opening a personal account',
        payments_guide_text_3: 'connecting the software that will enable you to accept payments and setting it up according to detailed instructions',
        payments_guide_button: 'Send request',
        payments_process_title: 'The payment process',
        payments_process_text_1: 'the customer places an order on the online shop`s website and has a choice of payment options;',
        payments_process_text_2: 'the customer chooses from the "Stablex Solution Payment Gateway" payment options;',
        payments_process_text_3: 'the customer pays for the order in cryptocurrency;',
        payments_process_text_4: 'after payment, the customer is informed about the successful transaction and the transaction information.',
        about_main_subtitle: 'a trusted cryptocurrency exchange partner',
        about_main_button: 'Leave request',
        about_info_title: 'About us',
        about_info_text_1: 'Stablex Solution helps improve the financial system by applying modern digital products and assets.',
        about_info_text_2: 'Modern financial services based on digital financial assets for businesses and individuals.',
        about_advantages_title: 'Why customers choose Stablex Solution',
        about_advantages_text_1: 'can work with all popular cryptocurrencies;',
        about_advantages_text_2: 'ensure full client confidentiality and data protection;',
        about_advantages_text_3: 'we offer favourable rates with minimal transaction fees;',
        about_advantages_text_4: 'we provide services in compliance with legal requirements and provide documents to support the transaction.',
        contacts_page_title:'Contact',
        contacts_page_subtitle:'Email us for more details or simply show us the feedback you left when you next visit us',
        information_title: 'A guide to cryptocurrency',
        information_subtitle: 'All types of cryptocurrencies have their own characteristics. In this section you can explore information about the most popular cryptocurrencies',
        information_button: 'Leave request',
        information_bitcoin_text_1: 'Bitcoin is a digital currency that was created in 2009. This cryptocurrency is based on blockchain technology, which uses a distributed database to record transactions and maintain network security. Unlike traditional currencies, bitcoin is not issued by a central bank or government. Instead, it is generated by users who "mine" bitcoins, using their computers to process transactions and protect the network.)',
        information_bitcoin_text_2: 'Bitcoins can be used to buy goods and services online and to transfer money to other countries without having to go to banks and payment systems. Bitcoin can also be used as an investment asset because its value can change depending on market demand.',
        information_bitcoin_text_3: 'Bitcoin is not subject to banks and financial regulators. The value of this cryptocurrency can jump up and down. Bitcoin refers to both the cryptocurrency and the payment system that uses it.',
        information_bitcoin_text_4: 'The features of bitcoin that set it apart from other forms of money',
        information_bitcoin_text_5: 'it is not subordinate to the banks;',
        information_bitcoin_text_6: 'it is not possible to block the transfer, freeze the money in the user`s wallet or return completed transactions;',
        information_bitcoin_text_7: 'cryptocurrency has no physical expression;',
        information_bitcoin_text_8: 'it is generated by miners',
        information_bitcoin_text_9: 'Bitcoins can be used to exchange for goods or services from merchants who agree to accept them. Exchange for conventional currencies takes place through online digital currency exchange services, other payment systems, exchange offices, or directly between interested parties. Bitcoin`s quotation depends solely on the balance of supply and demand and is not regulated or restrained by anyone.',
        information_bitcoin_text_10: 'One of the main features of bitcoin is complete decentralisation: there is no central administrator or any equivalent. The necessary and sufficient element of this payment system is the underlying client software. Client programs running on multiple computers are interconnected into a peer-to-peer network, each node of which is equal and self-sufficient. There can be no public or private management of the system, including changing the total number of bitcoins.',
        information_bitcoin_text_11: 'Bitcoins are stored in cryptocurrencies. The public key is the address of your wallet. If someone wants to send you bitcoins, they need to know this address. The private key is the password to your wallet. If you want to send bitcoins to someone, you will need to show this password to the system. When you create a cryptocurrency wallet, the client receives these two keys. They are linked by software operations.',
        information_bitcoin_text_12: 'There are two main types of cryptocurrency wallets: wallet on your device. You download the software, register the wallet and get the keys, then you can make bitcoin transactions; online wallet.',
        information_bitcoin_text_13: 'Once you register, all your bitcoins will be stored in the cloud. However, you should be aware that the service then has access to your wallet.',
        information_ethereum_text_1: 'Ethereum has been called the second cryptocurrency and the first altcoin. Ethereum is a universal distributed smart contract system based on blockchain technology. Ethereum is a blockchain-based system. It can be used to create a service or an application. And Ether (ETH) is a token that is used to transfer data within that service. Just like regular currency or securities are used in a traditional financial system with banks and exchanges.',
        information_ethereum_text_2: 'A token is essentially a unit of information, it can be:',
        information_ethereum_text_3: 'Non-fungible token - NFT (Non-fungible token).',
        information_ethereum_text_4: 'Interchangeable, in the case of Ether, naturally all Ether tokens are equal and interchangeable, otherwise they could not be used as money.',
        information_ethereum_text_5: 'Ethereum is more widely used because of its ability to create smart contracts quickly. Ethereum offers a wider range of actions that can be done with money. Money can be borrowed, invested, invested, lent, and so on. Smart contracts in Ethereum allow all this and provide the basis for a new economy, separate from states and banks. Ethereum extends the concept of cryptocurrency to the crypto-economy through smart contracts. Ethereum offers an economic system independent of them.',
        information_ethereum_text_6: 'What are the most popular ways of using Ethereum:',
        information_ethereum_text_7: '- in NFTs. Non-exchangeable tokens or NFTs are a type of digital token that grants the right to own something of value and uniqueness. Even real estate is tokenised. These tokenised items are created in the Ethereum blockchain, and each item can only have one real owner at a time.',
        information_ethereum_text_8: '- in DeFi. Any financial product or service running on Ethereum software is considered DeFi. With DeFi, there is no interaction with the central government, and markets are never really shut down.',
        information_ethereum_text_9: '- in FAO or decentralised autonomous organisation. It is a platform owned by its members that offers a safe and secure way for strangers to collaborate by exchanging funds for projects. It is a truly transparent, open source solution for collaborations on a global level.',
        information_ethereum_text_10: 'Ether is the financial asset underpinning everything that is and operates in the Ethereum ecosystem. You can manage your own funds with your wallet /wallet/ as proof of ownership - there is no need for third parties. ETH is decentralised and global. There is no single company or bank that can decide to print more ETH or change the terms of its use. ETH is the powerhouse of Ethereum.',
        information_tether_text_1: 'The main difference between USDT and Bitcoin and other cryptocurrencies is that it has no transaction blocks and is backed by fiat currency reserves. That is, Tether does not have its own blockchain. It uses someone else`s off-the-shelf decentralised systems to sell tokens. USDT can be purchased through money transfers to Tether Limited accounts. The functioning of USDT is supported by various open protocols.',
        information_tether_text_2: 'USDT is a Stablecoin, which is a cryptocurrency with a fixed exchange rate or resistance to significant fluctuations. This digital asset class is characterised by low or no volatility. Thus, in addition to their exchange function, staplecoins act as an effective unit of account and a means of savings.',
        information_tether_text_3: 'The USDT cryptocurrency has a number of important advantages:',
        information_tether_text_4: 'Full transparency of settlements. The creators of the asset claim to continually analyse the correspondence between the money in the company`s vault and the overall balance of client accounts.',
        information_tether_text_5: 'The speed of transactions is high. Sending and receiving coins takes a minimal amount of time.',
        information_tether_text_6: 'Low fee. Transactions between two Tether accounts are quite cheap.',
        information_tether_text_7: 'A high level of stability in the market. This cryptocurrency is backed by the most sought-after fiat currency in the world.',
        information_tether_text_8: 'The coin has received all the benefits of the highest protection from blockchain technology.',
        information_tether_text_9: 'You do not need to open a bank account to buy and sell USDT. On the Binance exchange, it can be bought for cryptocurrency or fiat.',
        information_tether_text_10: 'Investors should pay attention to the fact that if at some point the Stablecoin loses its collateral, its holders could incur losses. But this is only a theoretical risk, as USDT is currently one of the most popular tokens. In addition, for many members of the cryptocurrency community, the incomplete anonymity and lack of mining capabilities is a notable disadvantage. In order to store USDT tokens, you must create a wallet that supports the cryptocurrency. USDT cryptocurrency can be stored in any wallet or exchange, which provides the opportunity to trade this coin. The most popular wallets for storing the token.',
        modal_title: 'Leave your name, phone number or email address so we can contact you to arrange an appointment.'
      },
      ru: {
        header_item_1: 'Для Вас',
        header_item_1_1: 'Обмен криптовалют',
        header_item_1_2: 'Приeм платежей в криптовалюте',
        header_item_2: 'О компании',
        header_item_3: 'Инфо',
        header_item_4: 'Контакты',
        header_item_5: 'Войти',
        header_item_6: 'Регистрация',
        main_title: 'Stablex Solution - надежный партнер по обмену криптовалюты',
        main_subtitle: 'Cовременные финансовые услуги на базе цифровых финансовых активов для юридических и физических лиц',
        main_text: 'Stablex Solution помогает улучшить финансовую систему, применяя современные цифровые продукты и активы',
        main_button: 'Свяжитесь с нами',
        services_title: 'Наши сервисы',
        services_subtitle_1: 'Обмен',
        services_subtitle_2: 'Платежный шлюз',
        services_subtitle_3: 'Управление активами',
        services_text_1: 'Обмен криптовалют за наличные или кредитной картой',
        services_text_2: 'Прием онлайн-платежей в криптовалюте',
        services_text_3: 'Альтернативный способ заработать, доверив на управление криптоактивы',
        services_button: 'Узнать больше',
        financial_title: 'Почему цифровая финансовая система лучше классической фиатной',
        financial_text_1: 'низкие комиссии;',
        financial_text_2: 'нет посредников;',
        financial_text_3: 'высокая скорость проведения транзакций;',
        financial_text_4: 'полная конфиденциальность, прозрачность и безопасность всех участников.',
        info_title: 'Stablex Solution – это удобный, быстрый и надежный сервис для безопасного использования современных цифровых технологий.',
        info_subtitle: 'Начните использовать все возможности Stablex Solution уже сегодня!',
        info_button: 'Оставить заявку',
        advantage_title: 'Почему клиенты выбирают Stablex Solution',
        advantage_text_1: 'можем работать со всеми популярными криптовалютами;',
        advantage_text_2: 'обеспечиваем полную конфиденциальность клиента и защиту его данных;',
        advantage_text_3: 'предоставляем выгодный курс с минимальными комиссиями за операции;',
        advantage_text_4: 'предоставляем услуги учитывая требования законодательства и предоставляем документы для подтверждения сделки.',
        contacts_title: 'У Вас остались вопросы?',
        contacts_subtitle: 'Получите детальную консультацию у наших специалистов.',
        contacts_tel: 'Телефон:',
        contacts_mail: 'Эл.почта: ',
        contacts_address: 'Адрес:',
        login_name: 'ФИО',
        login_tel: 'Телефон',
        login_mail: ' Эл.почта',
        login_message: 'Сообщение',
        login_button: 'Отправить',
        footer_item_1: 'Для вас',
        footer_item_1_1: 'Обмен криптовалют',
        footer_item_1_2: 'Приeм платежей в криптовалюте',
        footer_item_2: 'КОМПАНИЯ',
        footer_item_2_1: 'О Stablex Solution',
        footer_item_2_2: 'AML',
        footer_item_3: 'ИНФОРМАЦИЯ',
        footer_item_3_1: 'Руководства по Crypto',
        exchange_title: 'Обмен криптовалют',
        exchange_subtitle: 'обмен криптовалют за наличные или кредитной картой для физических и юридических лиц',
        exchange_button: 'Оставить заявку',
        exchange_info_title: 'Про сервис',
        exchange_info_text_1: 'Stablex Solution – это онлайн-платформа, которая предоставляет возможность клиенту обменивать фиатные деньги на криптовалюту и наоборот.',
        exchange_info_text_2: 'Клиенты-физические лица могут купить или продать криптовалюту за наличные или кредитную карту. Клиенты-юридические лица осуществляют обмен через безналичный расчет.',
        exchange_info_text_3: 'Расчет проводится:',
        exchange_info_text_4: 'за фиатные деньги;',
        exchange_info_text_5: 'через банковский перевод;',
        exchange_info_text_6: 'через оплату кредитной картой.',
        exchange_advantage_title: 'Почему лучше покупать и продавать криптовалюту у нас',
        exchange_advantage_text_1: 'высокая ликвидность;',
        exchange_advantage_text_2: 'минимальные комиссии;',
        exchange_advantage_text_3: 'доступны разные способы оплаты;',
        exchange_advantage_text_4: 'гарантированная безопасность и конфиденциальность операций;',
        exchange_advantage_text_5: 'техническая поддержка клиента;',
        exchange_advantage_text_6: 'сервис доступный с любой точки мира.',
        exchange_ability_title: 'С помощью Stablex Solution Вы можете',
        exchange_ability_text_1: 'Купить или продать криптовалюту используя разные способы оплаты',
        exchange_ability_text_2: 'Провести сделку с полной гарантией безопасности, видя и общаясь со второй стороной лично, а также дистанционно',
        exchange_ability_text_3: 'Избежать блокировок со стороны банков, которые могут не пропустить цифровой платеж, а также заморозить или конфисковать средства',
        exchange_ability_button: 'Отправить заявку',
        payments_title: 'Прием платежей в криптовалюте',
        payments_subtitle: 'прием онлайн-платежей в криптовалюте – сервис для коммерческих сайтов',
        payments_button: 'Оставить заявку',
        payments_info_title: 'Про сервис',
        payments_info_text_1: 'Это онлайн-сервис, который обрабатывает платежи между покупателем и продавцом – интернет‑магазином. Таким образом эта платформа помогает коммерческим сайтам принимать онлайн‑платежи от покупателей в криптовалюте. Для использования сервиса продавец должен зарегистрироваться на платформе и подключить программное обеспечение для получения платежей.',
        payments_info_text_2: 'Наша платформа значительно упрощает процесс проведения онлайн‑платежей в криптовалюте для покупателей и продавцов и способствует популяризации использования криптовалюты как средства оплаты за предоставленный товар.',
        payments_info_text_3: 'Все платежи проводятся с прозрачной и полной статистикой по всем операциям. Средства доступны для вывода сразу после получения.',
        payments_advantage_title: 'Преимущества',
        payments_advantage_text_1: 'быстрое подключение',
        payments_advantage_text_2: 'минимальная комиссия',
        payments_advantage_text_3: 'понятный и приятный интерфейс',
        payments_guide_title: 'Этапы подключения',
        payments_guide_text_1: 'заполнение заявки на подключение',
        payments_guide_text_2: 'открытие личного кабинета',
        payments_guide_text_3: 'подключение программного обеспечения, которое позволит принимать платежи, и его настройка по детальной инструкции',
        payments_guide_button: 'Отправить заявку',
        payments_process_title: 'Процесс проведения платежей',
        payments_process_text_1: 'клиент осуществляет заказ на сайте интернет-магазина и получает возможность выбора способа оплаты;',
        payments_process_text_2: 'клиент выбирает из вариантов оплаты «Stablex Solution Payment Gateway»;',
        payments_process_text_3: 'клиент оплачивает заказ криптовалютой;',
        payments_process_text_4: 'после оплаты у клиента появляется информация об успешной операции и информация о транзакции.',
        about_main_subtitle: 'надежный партнер по обмену криптовалюты',
        about_main_button: 'Оставить заявку',
        about_info_title: 'О компании',
        about_info_text_1: 'Stablex Solution помогает улучшить финансовую систему, применяя современные цифровые продукты и активы.',
        about_info_text_2: 'Современные финансовые услуги на базе цифровых финансовых активов для юридических и физических лиц.',
        about_advantages_title: 'Почему клиенты выбирают Stablex Solution',
        about_advantages_text_1: 'можем работать со всеми популярными криптовалютами;',
        about_advantages_text_2: 'обеспечиваем полную конфиденциальность клиента и защиту его данных;',
        about_advantages_text_3: 'предоставляем выгодный курс с минимальными комиссиями за операции;',
        about_advantages_text_4: 'предоставляем услуги учитывая требования законодательства и предоставляем документы для подтверждения сделки.',
        contacts_page_title:'Контакты',
        contacts_page_subtitle:'Для уточнения подробностей напишите нам на электронную почту или просто покажите оставленный Вами отзыв при следующем визите к нам',
        information_title: 'Руководство по криптовалюте',
        information_subtitle: 'Все виды криптовалют имеют свои особенности. В этом разделе есть возможность изучить информацию о самых популярных криптовалютах',
        information_button: 'Оставить заявку',
        information_bitcoin_text_1: 'Биткоин - это цифровая валюта, которая была создана в 2009 году. Эта криптовалюта основана на технологии блокчейн, которая использует распределенную базу данных для записи транзакций и поддержания безопасности сети. В отличие от традиционных валют, биткоин не выпускается центральным банком или пра вительством. Вместо этого он генерируются пользователями, которые занимаются "майнингом" биткоинов, используя свои компьютеры для обработки транзакций и защиты сети.',
        information_bitcoin_text_2: 'Биткоины можно использовать для покупки товаров и услуг в Интернете, а также для перевода денег в другие страны без необходимости обращаться к банкам и платежным системам. Биткоин также может быть использован как инвестиционный актив, поскольку его стоимость может изменяться в зависимости от спроса на него на рынке.',
        information_bitcoin_text_3: 'Курс биткоина не подчиняется банкам и финансовым регуляторам. Стоимость этой криптовалюты способна делать скачки и вверх, и вниз. Биткоином называют и криптовалюту, и платёжную систему, которая её использует.',
        information_bitcoin_text_4: 'Особенности биткойна, отличающие его от остальных видов денег:',
        information_bitcoin_text_5: 'он не подчиняется банкам;',
        information_bitcoin_text_6: 'нельзя заблокировать перевод, заморозить деньги в кошельке пользователя или вернуть совершенные транзакции;',
        information_bitcoin_text_7: 'криптовалюта не имеет физического выражения;',
        information_bitcoin_text_8: 'он генерируется майнерами.',
        information_bitcoin_text_9: 'Биткоины могут использоваться для обмена на товары или услуги у продавцов, которые согласны их принимать. Обмен на обычные валюты происходит через онлайн-сервисы обмена цифровых валют, другие платёжные системы, обменные пункты или непосредственно между заинтересованными сторонами. Котировка биткойна зависит исключительно от баланса спроса и предложения, она никем не регулируется и не сдерживается.',
        information_bitcoin_text_10: 'Одна из главных особенностей биткоина — полная децентрализация: нет центрального администратора или какого-либо его аналога. Необходимым и достаточным элементом этой платёжной системы является базовая программа-клиент. Запущенные на множестве компьютеров программы-клиенты соединяются между собой в одноранговую сеть, каждый узел которой равноправен и самодостаточен. Невозможно государственное или частное управление системой, в том числе изменение суммарного количества биткоинов.',
        information_bitcoin_text_11: 'Биткоины хранятся в криптокошельках. Публичный ключ — это адрес вашего кошелька. Если кто-то хочет отправить вам биткойны, ему нужно знать этот адрес. Закрытый ключ — это пароль к вашему кошельку. Если вы хотите отправить кому-то биткоины, то вам необходимо предъявить этот пароль системе. При создании криптокошелька, клиент получает эти два ключа. Между собой они связаны программными операциями.',
        information_bitcoin_text_12: 'Существует два основных типа криптокошельков: кошелек на вашем устройстве. Вы скачиваете программу, регистрируете кошелек и получаете ключи, после чего можете совершать транзакции с биткойнами; онлайн-кошелек.',
        information_bitcoin_text_13: 'После регистрации все ваши биткойны будут храниться в облаке. Однако нужно понимать, что в этом случае сервис имеет доступ к вашему кошельку.',
        information_ethereum_text_1: 'Ethereum называют второй криптовалютой и первым альткоином. Ethereum - это универсальная распределенная система смарт-контрактов на базе блокчейн-технологии. Ethereum — это блокчейн-система. Ее можно использовать для создания какого-либо сервиса или приложения. А Ether (ETH) — это токен, который используется для передачи данных внутри этого сервиса. Также как обычная валюта или ценные бумаги используются в традиционной финансовой системе с банками и биржами.',
        information_ethereum_text_2: 'Токен, по сути, — это единица информации, он может быть:',
        information_ethereum_text_3: 'Невзаимозаменяемым, то есть уникальным — NFT (Non-fungible token).',
        information_ethereum_text_4: 'Взаимозаменяемым, в случае если это Ether, естественно, все токены эфира равны и взаимозаменяемы, иначе их нельзя было бы использовать как деньги.',
        information_ethereum_text_5: 'Ethereum более широко используется благодаря возможности быстро создавать смарт-контракты. Ethereum предлагает более широкий спектр действий, которые можно выполнять с деньгами. Деньги можно брать взаймы, вкладывать, инвестировать, одалживать и так далее. Смарт-контракты в Ethereum позволяют все это и обеспечивают основу для новой экономики, отдельной от государств и банков. Ethereum расширяет концепцию криптовалюты до криптоэкономики посредством смарт-контрактов. Эфириум предлагает независимую от них экономическую систему.',
        information_ethereum_text_6: 'Какие самые популярные способы использования Ethereum:',
        information_ethereum_text_7: '- в NFT. Невзаимозаменяемые токены или NFT представляют собой разновидность цифрового токена, который предоставляет право на владение чем-то ценным и уникальным. Токенизируется даже недвижимость. Эти токенизированные элементы создаются в блокчейне Ethereum, при этом у каждого элемента одновременно может быть только один настоящий владелец.',
        information_ethereum_text_8: '- в DeFi. Любой финансовый продукт или услуга, работающая на программном обеспечении Ethereum, считается DeFi. С DeFi отсутствует взаимодействие с центральной властью, и рынки никогда не закрываются на самом деле.',
        information_ethereum_text_9: '- в ДАО или децентрализованная автономная организация. Ппредставляет собой платформу, принадлежащую ее участникам, которая предлагает безопасный и надежный способ сотрудничества между незнакомцами путем обмена средств на проекты. Это по-настоящему прозрачное решение с открытым исходным кодом для осуществления сотрудничества на глобальном уровне.',
        information_ethereum_text_10: 'Эфир (Ether) – это финансовый актив, лежащий в основе всего, что находится и работает в экосистеме Ethereum. Вы можете управлять своими собственными средствами с помощью вашего кошелька /wallet/ в качестве доказательства владения – нет необходимости в третьих лицах. ETH является децентрализованным и глобальным. Нет ни одной компании или банка, которые могут решить напечатать больше ETH или изменить условия его использования. ETH является силовым двигателем Ethereum.',
        information_tether_text_1: 'Главное отличие USDT от Вitcoin и других криптовалют сводится к тому, что он не имеет блоков транзакций и обеспечивается резервами фиатной валюты. То есть у Tether нет своего блокчейна. Для реализации токенов используются уже готовые чужие децентрализованные системы. Покупать USDT можно при помощи денежных переводов на счета компании Tether Limited. Функционирование USDT поддерживается разными открытыми протоколами.',
        information_tether_text_2: 'USDT это стейблкоин, тоесть криптовалюта с фиксированным курсом или устойчивостью к значительным колебаниям. Этот класс цифровых активов характеризуется низкой волатильностью курса или ее полным отсутствием. Таким образом, помимо функции обмена, стейблкоины выступают эффективной расчетной единицей и средством сбережения.',
        information_tether_text_3: 'Криптовалюта USDT имеет ряд важных преимуществ:',
        information_tether_text_4: 'Полная прозрачность расчетов. Создатели актива утверждают, что на постоянной основе проводят анализ соответствия денег в хранилище компании и общего баланса клиентских счетов.',
        information_tether_text_5: 'Высокая скорость проведения транзакций. Отправка и получение монет занимает минимальное количество времени.',
        information_tether_text_6: 'Низкая комиссия. Проведение транзакций между двумя учетными записями Tether обходится достаточно дешево.',
        information_tether_text_7: 'Высокий уровень устойчивости на рынке. Эта криптовалюта обеспечена самой востребованной фиатной валютой в мире.',
        information_tether_text_8: 'Монета получила все преимущества высочайшей защиты от блокчейн технологий.',
        information_tether_text_9: 'Для покупки и продажи USDT не обязательно открывать счет в банке. На бирже Binance его можно купить за криптовалюту или фиат.',
        information_tether_text_10: 'Инвесторам стоит обращать внимание на то, что еслы в какой-то момент стейблкоин лишится своего обеспечения, его держатели могут понести убытки. Но это только теоретический риск, поскольку на данный момент USDT является одним из самых популярных токенов. Кроме того, для многих участников криптовалютного сообщества заметным минусом является неполная анонимность и отсутствие возможности майнинга. Для хранения токенов USDT необходимо создать кошелек, который поддерживает эту криптовалюту. Криптовалюта USDT может храниться в любом кошельке или на бирже, которая предоставляет возможность торговли этой монетой. Наиболее популярные кошельки для хранения токена.',
        modal_title: 'Оставьте свое имя, номер телефона или адрес эл. почты, чтобы мы могли связаться с Вами и назначить встречу.'
      }, 
      pl: {
        header_item_1: 'Dla ciebie',
        header_item_1_1: 'Wymiana kryptowalut',
        header_item_1_2: 'Akceptacja płatności kryptowalutowych',
        header_item_2: 'O nas',
        header_item_3: 'Info',
        header_item_4: 'Kontakt',
        header_item_5: 'Zaloguj się',
        header_item_6: 'Zarejestruj się',
        main_title: 'Stablex Solution - zaufany partner w zakresie wymiany kryptowalut',
        main_subtitle: 'Nowoczesne usługi finansowe oparte na cyfrowych aktywach finansowych dla firm i osób fizycznych',
        main_text: 'Stablex Solution pomaga usprawnić system finansowy poprzez zastosowanie nowoczesnych produktów i aktywów cyfrowych',
        main_button: 'Skontaktuj się z nami',
        services_title: 'Nasze usługi',
        services_subtitle_1: 'Wymiana',
        services_subtitle_2: 'Bramka płatności',
        services_subtitle_3: 'Zarządzanie aktywami',
        services_text_1: 'Wymiana kryptowalut na gotówkę lub kartę kredytową',
        services_text_2: 'Przyjmowanie płatności online w kryptowalucie',
        services_text_3: 'Alternatywny sposób na zarabianie pieniędzy poprzez powierzenie kryptowalut do zarządzania',
        services_button: 'Dowiedz się więcej',
        financial_title: 'Dlaczego cyfrowy system finansowy jest lepszy niż klasyczny fiat',
        financial_text_1: 'niskie prowizje;',
        financial_text_2: 'nie ma żadnych pośredników;',
        financial_text_3: 'duża szybkość transakcji;',
        financial_text_4: 'pełna poufność, przejrzystość i bezpieczeństwo dla wszystkich uczestników.',
        info_title: 'Stablex Solution to wygodna, szybka i niezawodna usługa bezpiecznego korzystania z nowoczesnych technologii cyfrowych.',
        info_subtitle: 'Zacznij korzystać z wszystkich funkcji Stablex Solution już dziś!',
        info_button: 'Zostawić wniosek',
        advantage_title: 'Dlaczego klienci wybierają Stablex Solution',
        advantage_text_1: 'może pracować ze wszystkimi popularnymi kryptowalutami;',
        advantage_text_2: 'zapewnienie pełnej poufności klienta i ochrony danych;',
        advantage_text_3: 'oferujemy korzystne stawki przy minimalnych opłatach transakcyjnych;',
        advantage_text_4: 'świadczymy usługi zgodnie z wymogami prawa i dostarczamy dokumenty potwierdzające transakcję.',
        contacts_title: 'Czy masz jeszcze jakieś pytania?',
        contacts_subtitle: 'Skorzystaj ze szczegółowych porad naszych specjalistów.',
        contacts_tel: 'Telefon:',
        contacts_mail: 'Email: ',
        contacts_address: 'Adres:',
        login_name: 'Twoje imię i nazwisko',
        login_tel: 'Telefon',
        login_mail: 'Email',
        login_message: 'Wiadomość',
        login_button: 'Wyślij',
        footer_item_1: 'Dla ciebie',
        footer_item_1_1: 'Wymiana kryptowalut',
        footer_item_1_2: 'Akceptacja płatności kryptowalutowych',
        footer_item_2: 'FIRMA',
        footer_item_2_1: 'O Stablex Solution',
        footer_item_2_2: 'AML',
        footer_item_3: 'INFO',
        footer_item_3_1: 'Instrukcje obsługi kryptowalut',
        exchange_title: 'Wymiana kryptowalut',
        exchange_subtitle: 'wymiana kryptowalut za gotówkę lub kartę kredytową dla osób prywatnych i firm',
        exchange_button: 'Zostawić wniosek',
        exchange_info_title: 'O serwisie',
        exchange_info_text_1: 'Stablex Solution to platforma internetowa, która zapewnia klientowi możliwość wymiany pieniędzy fiat na kryptowaluty i odwrotnie.',
        exchange_info_text_2: 'Klienci indywidualni mogą kupować lub sprzedawać kryptowaluty za pomocą gotówki lub karty kredytowej. Klienci-podmioty prawne dokonują wymiany za pomocą płatności bezgotówkowej.',
        exchange_info_text_3: 'Obliczenia przeprowadza się:',
        exchange_info_text_4: 'dla fiat money;',
        exchange_info_text_5: 'poprzez przelew bankowy;',
        exchange_info_text_6: 'poprzez płatność kartą kredytową.',
        exchange_advantage_title: 'Dlaczego warto kupować i sprzedawać kryptowaluty u nas',
        exchange_advantage_text_1: 'wysoka płynność;',
        exchange_advantage_text_2: 'minimalne prowizje;',
        exchange_advantage_text_3: 'dostępne są różne metody płatności;',
        exchange_advantage_text_4: 'gwarantowane bezpieczeństwo i poufność transakcji;',
        exchange_advantage_text_5: 'techniczne wsparcie klienta;',
        exchange_advantage_text_6: 'usługa jest dostępna z każdego miejsca na świecie.',
        exchange_ability_title: 'Dzięki rozwiązaniu Stablex można',
        exchange_ability_text_1: 'Uniknięcie blokady przez banki, które mogą nie przepuścić płatności cyfrowej i zamrozić lub skonfiskować środki',
        exchange_ability_text_2: 'Przeprowadzić transakcję z pełnym bezpieczeństwem, widząc i komunikując się z drugą stroną osobiście, jak i zdalnie',
        exchange_ability_text_3: 'Uniknięcie blokady przez banki, które mogą nie przepuścić płatności cyfrowej i zamrozić lub skonfiskować środki',
        exchange_ability_button: 'Wyślij wniosek',
        payments_title: 'Akceptacja płatności kryptowalutowych',
        payments_subtitle: 'przyjmowanie płatności online w kryptowalucie - usługa dla komercyjnych serwisów internetowych',
        payments_button: 'Zostawić wniosek',
        payments_info_title: 'O usługach',
        payments_info_text_1: 'Jest to usługa internetowa, która przetwarza płatności między kupującym a kupcem - sklepem internetowym. Tak więc ta platforma pomaga witrynom handlowym akceptować płatności online od kupujących w kryptowalucie. Aby skorzystać z usługi, kupiec musi zarejestrować się na platformie i podłączyć oprogramowanie do otrzymywania płatności.',
        payments_info_text_2: 'Nasza platforma znacznie upraszcza proces dokonywania płatności online w kryptowalucie dla kupujących i sprzedających oraz promuje wykorzystanie kryptowaluty jako środka płatniczego za dostarczane towary.',
        payments_info_text_3: 'Wszystkie płatności są realizowane z zachowaniem przejrzystych i pełnych statystyk wszystkich transakcji. Środki są dostępne do wypłaty zaraz po ich otrzymaniu.',
        payments_advantage_title: 'Zalety',
        payments_advantage_text_1: 'szybkie połączenie',
        payments_advantage_text_2: 'opłata minimalna',
        payments_advantage_text_3: 'przyjazny dla użytkownika interfejs',
        payments_guide_title: 'Kroki łączące',
        payments_guide_text_1: 'szybkie połączenie',
        payments_guide_text_2: 'otwarcie konta osobistego',
        payments_guide_text_3: 'podłączenie oprogramowania, które umożliwi Ci przyjmowanie płatności i skonfigurowanie go zgodnie ze szczegółową instrukcją',
        payments_guide_button: 'Wyślij wniosek',
        payments_process_title: 'Proces płatności',
        payments_process_text_1: 'klient składa zamówienie na stronie sklepu internetowego i ma możliwość wyboru opcji płatności;',
        payments_process_text_2: 'klient wybiera spośród opcji płatności "Stablex Solution Payment Gateway";',
        payments_process_text_3: 'klient płaci za zamówienie w kryptowalucie;',
        payments_process_text_4: 'po dokonaniu płatności, klient jest informowany o udanej transakcji oraz o informacjach dotyczących transakcji.',
        about_main_subtitle: 'zaufany partner w zakresie wymiany kryptowalut',
        about_main_button: 'Zostawić wniosek',
        about_info_title: 'O nas',
        about_info_text_1: 'Stablex Solution pomaga usprawnić system finansowy poprzez zastosowanie nowoczesnych produktów i aktywów cyfrowych.',
        about_info_text_2: 'Nowoczesne usługi finansowe oparte na cyfrowych aktywach finansowych dla firm i osób prywatnych.',
        about_advantages_title: 'Dlaczego klienci wybierają Stablex Solution',
        about_advantages_text_1: 'może pracować ze wszystkimi popularnymi kryptowalutami;',
        about_advantages_text_2: 'zapewnienie pełnej poufności klienta i ochrony danych;',
        about_advantages_text_3: 'oferujemy korzystne stawki przy minimalnych opłatach transakcyjnych;',
        about_advantages_text_4: 'świadczymy usługi zgodnie z wymogami prawa i dostarczamy dokumenty potwierdzające transakcję.',
        contacts_page_title:'Kontakt',
        contacts_page_subtitle:'Napisz do nas po więcej szczegółów lub po prostu pokaż nam opinię, którą zostawiłeś przy następnej wizycie.',
        information_title: 'Przewodnik po kryptowalutach',
        information_subtitle: 'Wszystkie rodzaje kryptowalut mają swoje własne cechy. W tej sekcji możesz odkryć informacje o najpopularniejszych kryptowalutach',
        information_button: 'Zostawić wniosek',
        information_bitcoin_text_1: 'Bitcoin to cyfrowa waluta, która powstała w 2009 roku. Ta kryptowaluta oparta jest na technologii blockchain, która wykorzystuje rozproszoną bazę danych do rejestrowania transakcji i utrzymywania bezpieczeństwa sieci. W przeciwieństwie do tradycyjnych walut, bitcoin nie jest emitowany przez bank centralny lub rząd. Zamiast tego jest generowany przez użytkowników, którzy "wydobywają" bitcoiny, używając swoich komputerów do przetwarzania transakcji i ochrony sieci.)',
        information_bitcoin_text_2: 'Bitcoiny mogą być używane do kupowania towarów i usług online oraz do przekazywania pieniędzy do innych krajów bez konieczności korzystania z banków i systemów płatności. Bitcoin może być również używany jako aktywo inwestycyjne, ponieważ jego wartość może się zmieniać w zależności od popytu rynkowego.',
        information_bitcoin_text_3: 'Bitcoin nie podlega bankom i regulatorom finansowym. Wartość tej kryptowaluty może skakać w górę i w dół. Bitcoin odnosi się zarówno do kryptowaluty, jak i do systemu płatności, który ją wykorzystuje.',
        information_bitcoin_text_4: 'Cechy bitcoina, które odróżniają go od innych form pieniądza',
        information_bitcoin_text_5: 'nie jest podporządkowany bankom;',
        information_bitcoin_text_6: 'nie ma możliwości zablokowania przelewu, zamrożenia pieniędzy w portfelu użytkownika czy zwrotu zrealizowanych transakcji;',
        information_bitcoin_text_7: 'kryptowaluta nie ma fizycznego wyrazu;',
        information_bitcoin_text_8: 'jest generowany przez górników',
        information_bitcoin_text_9: 'Bitcoiny mogą być używane do wymiany na towary lub usługi u sprzedawców, którzy zgodzą się je przyjąć. Wymiana na waluty konwencjonalne odbywa się za pośrednictwem internetowych serwisów wymiany walut cyfrowych, innych systemów płatności, kantorów lub bezpośrednio między zainteresowanymi stronami. Notowania Bitcoina zależą wyłącznie od równowagi podaży i popytu i nie są przez nikogo regulowane ani ograniczane.',
        information_bitcoin_text_10: 'Jedną z głównych cech bitcoina jest całkowita decentralizacja: nie ma centralnego administratora ani żadnego jego odpowiednika. Koniecznym i wystarczającym elementem tego systemu płatności jest bazowe oprogramowanie klienckie. Programy klienckie działające na wielu komputerach są połączone w sieć peer-to-peer, której każdy węzeł jest równy i samowystarczalny. Nie może istnieć żadne publiczne lub prywatne zarządzanie systemem, w tym zmiana całkowitej liczby bitcoinów.',
        information_bitcoin_text_11: 'Bitcoiny przechowywane są w kryptowalutach. Klucz publiczny to adres Twojego portfela. Jeśli ktoś chce wysłać Ci bitcoiny, musi znać ten adres. Klucz prywatny to hasło do Twojego portfela. Jeśli chcesz wysłać komuś bitcoiny, będziesz musiał pokazać to hasło systemowi. Kiedy tworzysz portfel kryptowalutowy, klient otrzymuje te dwa klucze. Są one połączone przez operacje programowe.',
        information_bitcoin_text_12: 'Istnieją dwa główne rodzaje portfeli kryptowalutowych: portfel na urządzeniu. Pobierasz oprogramowanie, rejestrujesz portfel i otrzymujesz klucze, a następnie możesz dokonywać transakcji bitcoinami; portfel online.',
        information_bitcoin_text_13: 'Po rejestracji wszystkie Twoje bitcoiny będą przechowywane w chmurze. Należy jednak mieć świadomość, że serwis ma wtedy dostęp do Twojego portfela.',
        information_ethereum_text_1: 'Ethereum zostało nazwane drugą kryptowalutą i pierwszym altcoinem. Ethereum to uniwersalny rozproszony system inteligentnych kontraktów oparty na technologii blockchain. Ethereum to system oparty na blockchainie. Można go wykorzystać do stworzenia usługi lub aplikacji. A Ether (ETH) to token, który służy do przekazywania danych w ramach tej usługi. Tak jak zwykła waluta lub papiery wartościowe są używane w tradycyjnym systemie finansowym z bankami i giełdami.',
        information_ethereum_text_2: 'Token jest zasadniczo jednostką informacji, może to być:',
        information_ethereum_text_3: 'Żeton niezbywalny - NFT (Non-fungible token).',
        information_ethereum_text_4: 'Wymienny, w przypadku Ether, naturalnie wszystkie tokeny Ether są równe i wymienne, inaczej nie mogłyby być używane jako pieniądze.',
        information_ethereum_text_5: 'Ethereum jest szerzej stosowane ze względu na możliwość szybkiego tworzenia inteligentnych kontraktów. Ethereum oferuje szerszy zakres działań, które można wykonać za pomocą pieniędzy. Pieniądze mogą być pożyczane, inwestowane, inwestowane, pożyczane i tak dalej. Inteligentne kontrakty w Ethereum pozwalają na to wszystko i stanowią podstawę nowej gospodarki, odrębnej od państw i banków. Ethereum rozszerza koncepcję krypto-waluty na krypto-ekonomię poprzez inteligentne kontrakty. Ethereum oferuje system gospodarczy niezależny od nich.',
        information_ethereum_text_6: 'Jakie są najpopularniejsze sposoby wykorzystania Ethereum:',
        information_ethereum_text_7: '- w NFT. Niewymienne tokeny lub NFT są rodzajem cyfrowego tokenu, który przyznaje prawo do posiadania czegoś o wartości i unikalności. Nawet nieruchomości są tokenizowane. Te tokenizowane przedmioty są tworzone w blockchainie Ethereum, a każdy przedmiot może mieć tylko jednego prawdziwego właściciela w danym czasie.',
        information_ethereum_text_8: '- w DeFi. Każdy produkt lub usługa finansowa działająca na oprogramowaniu Ethereum jest uważana za DeFi. Dzięki DeFi nie ma interakcji z rządem centralnym, a rynki nigdy nie są naprawdę zamknięte.',
        information_ethereum_text_9: '- w FAO czyli zdecentralizowanej organizacji autonomicznej. Jest to platforma będąca własnością jej członków, która oferuje bezpieczny i pewny sposób współpracy dla obcych ludzi poprzez wymianę środków na projekty. Jest to prawdziwie przejrzyste, otwarte rozwiązanie dla współpracy na poziomie globalnym.',
        information_ethereum_text_10: 'Ether jest aktywem finansowym stanowiącym podstawę wszystkiego, co jest i działa w ekosystemie Ethereum. Możesz zarządzać swoimi własnymi funduszami za pomocą portfela /wallet/ jako dowodu własności - nie ma potrzeby korzystania z usług osób trzecich. ETH jest zdecentralizowany i globalny. Nie ma jednej firmy lub banku, który może zdecydować o dodruku większej ilości ETH lub zmienić warunki jego użytkowania. ETH jest siłą napędową Ethereum.',
        information_tether_text_1: 'Główną różnicą między USDT a Bitcoinem i innymi kryptowalutami jest to, że nie posiada bloków transakcyjnych i jest wspierany przez rezerwy waluty fiat. To znaczy, że Tether nie ma własnego blockchaina. Wykorzystuje czyjeś off-the-shelf zdecentralizowane systemy do sprzedaży tokenów. USDT można kupić poprzez transfery pieniężne na konta Tether Limited. Funkcjonowanie USDT jest wspierane przez różne otwarte protokoły.',
        information_tether_text_2: 'USDT to Stablecoin, czyli kryptowaluta o stałym kursie wymiany lub odporności na znaczne wahania. Ta klasa aktywów cyfrowych charakteryzuje się niską lub zerową zmiennością. Dzięki temu, oprócz funkcji wymiany, staplecoiny działają jako efektywna jednostka rozliczeniowa i środek oszczędnościowy.',
        information_tether_text_3: 'Kryptowaluta USDT ma kilka ważnych zalet:',
        information_tether_text_4: 'Pełna przejrzystość rozliczeń. Twórcy aktywa twierdzą, że nieustannie analizują korespondencję między pieniędzmi w skarbcu firmy a ogólnym stanem kont klientów.',
        information_tether_text_5: 'Szybkość przeprowadzania transakcji jest wysoka. Wysyłanie i odbieranie monet zajmuje minimalną ilość czasu.',
        information_tether_text_6: 'Niskie opłaty. Transakcje pomiędzy dwoma kontami Tether są dość tanie.',
        information_tether_text_7: 'Wysoki poziom stabilności na rynku. Ta kryptowaluta jest wspierana przez najbardziej poszukiwaną walutę fiat na świecie.',
        information_tether_text_8: 'Moneta otrzymała wszystkie korzyści z najwyższej ochrony z technologii blockchain.',
        information_tether_text_9: 'Nie musisz otwierać konta bankowego, aby kupować i sprzedawać USDT. Na giełdzie Binance można go kupić za kryptowalutę lub fiat.',
        information_tether_text_10: 'Inwestorzy powinni zwrócić uwagę na fakt, że jeśli w pewnym momencie Stablecoin straci swoje zabezpieczenie, jego posiadacze mogą ponieść straty. Jest to jednak tylko teoretyczne ryzyko, ponieważ USDT jest obecnie jednym z najpopularniejszych tokenów. Ponadto, dla wielu członków społeczności kryptowalutowej, niepełna anonimowość i brak możliwości wydobywania jest zauważalną wadą. Aby przechowywać tokeny USDT, musisz stworzyć portfel obsługujący tę kryptowalutę. Kryptowaluta USDT może być przechowywana w dowolnym portfelu lub na giełdzie, która zapewnia możliwość handlu tą monetą. Najpopularniejsze portfele do przechowywania tokena.',
        modal_title: 'Zostaw swoje imię, numer telefonu lub adres e-mail, abyśmy mogli się z Tobą skontaktować w celu umówienia spotkania.'
      }
    }
});
export default i18n;
const app = createApp({})
app.use(i18n)