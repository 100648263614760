<template>
    <article class="information-article">
        <information-main-section/>
        <information-crypto-section/>
        <contacts-section/>
        <scroll-button/>
    </article>
</template>
<script>
    import InformationMainSection from '../InformationPage/InformationPageItems/InformationMainSection.vue';
    import InformationCryptoSection from './InformationPageItems/InformationCryptoSection.vue';
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue'
    export default {    
        name: 'InformationPage',
        components: {
            InformationMainSection,
            InformationCryptoSection,
            ContactsSection,
            ScrollButton
        }
    }
</script>
<style lang="scss">
    .information-article {
        position: relative;
    }
</style>