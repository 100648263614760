<template>
  <div>
    <header-item/>
    <main-wrapper/>
    <footer-item/>
  </div>
</template>

<script>
import HeaderItem from './components/HeaderItem.vue';
import MainWrapper from './components/MainWrapper.vue';
import FooterItem from './components/FooterItem.vue';
export default {
  name: 'App',
  components: {
    HeaderItem,
    MainWrapper, 
    FooterItem
  },
  metaInfo: {
      title: 'Stablex Solution - надежный партнер по обмену криптовалюты',
    }
}
</script>

<style lang="scss">
  
</style>
