<template>           
    <section class="about-info">
        <div class="about-info__wrapper wrapper">            
            <div class="about-info__content">
                <div class="about-info__img-wrap about-info__desktop">
                    <img class="about-info__img" src="../../../assets/images/i6.png" alt="img">
                </div> 
                <div class="about-info__item"> 
                    <h2 class="about-info__title">{{ $t('about_info_title') }}</h2>
                    <div class="line"></div>
                    <div class="about-info__img-wrap about-info__mobile">
                        <img class="about-info__img" src="../../../assets/images/i6.png" alt="img">
                    </div> 
                    <div class="about-info__text">
                        <p>{{ $t('about_info_text_1') }}</p>                         
                        <p>{{ $t('about_info_text_2') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {    
        name: 'AboutInfoSection'        
    }
</script>

<style lang="scss">
.about-info {       
    background-color:#03031c;
    color: #fff;
    padding: 110px 0;
    @media (max-width: 990px) {
        padding-bottom: 80px;   
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        @media (max-width: 990px) {
            width: 100%;
            align-items: center;
        }
    }
    &__title {
        font-size: 34px;    
        font-weight: 700;
        text-align: center;
        @media (max-width: 990px) {
            margin: 0; 
        }
        @media (max-width: 600px) {
            font-size: 26px;               
        } 
    }
    &__content {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        justify-content: space-between;
        gap: 50px;
        width: 100%;
        @media (max-width: 990px) {
           flex-direction: column;   
           gap: 50px;   
           align-items: center;
        }
    }
    &__text {
        font-size: 21px;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media (max-width: 990px) {
            margin-top: 60px; 
            text-align: center;
        }        
    }
    &__img-wrap {
        margin-right: 50px;
        width: 400px;
        height: 400px;
        position: relative;
        @media (max-width: 990px) {
            margin: 0; 
        }
        @media (max-width: 600px) {
            width: 245px;
            height: 250px;             
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    &__desktop {
        @media (max-width: 990px) {
            display: none;
        }
    }
    &__mobile {
        @media (min-width: 991px) {
            display: none;
        }
    }
}
</style>