<template>
    <article class="about-article">
        <about-main-section/>
        <about-info-section/>
        <about-advantage-section/>
        <contacts-section/>
        <scroll-button/>        
    </article>
</template>
<script>
    import AboutMainSection from './StablexSolutionItems/AboutMainSection.vue';
    import AboutInfoSection from './StablexSolutionItems/AboutInfoSection.vue';
    import AboutAdvantageSection from './StablexSolutionItems/AboutAdvantageSection.vue'
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue'
    export default {    
        name: 'StablexSolution',
        components: {
            AboutMainSection,
            ContactsSection,
            AboutInfoSection,
            AboutAdvantageSection,
            ScrollButton
        }
    }
</script>
<style lang="scss">
    .about-article {
        position: relative;
    }
</style>