<template>
  <form class="login-form" @submit.prevent="submitForm">   
    <div class="login-form__field">
      <input class="login-form__input" autocomplete="off" :placeholder="$t('login_name') + '&#10038;'" type="text" id="name" v-model="name" >
      <div class="login-form__error" v-if="nameError">{{ nameError }}</div>
    </div>
    <div class="login-form__field">
      <input class="login-form__input" autocomplete="off" :placeholder="$t('login_mail') + '&#10038;'" type="email" id="email" v-model="email" >
      <div class="login-form__error" v-if="emailError">{{ emailError }}</div>
    </div> 
    <div class="login-form__field">
      <input class="login-form__input" autocomplete="off" :placeholder="$t('login_tel') + '&#10038;'" type="tel" id="tel" v-model="tel" pattern="[0-9]{10}" >
      <div class="login-form__error" v-if="telError">{{ telError }}</div>    
    </div>  
    <div class="login-form__field">
      <textarea class="login-form__input login-form__input--special" :placeholder="$t('login_message') + '&#10038;'" id="interest" v-model="interest" ></textarea>
      <div class="login-form__error" v-if="interestError">{{ interestError }}</div>
    </div>
    <button class="login-form__button btn" type="submit">{{ $t('login_button') }}</button>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      interest: '',
      nameError: '',
      emailError: '',
      telError: '',
      interestError: ''
    }
  },
  methods: {
    submitForm() {
      this.validateForm()
      if (!this.nameError && !this.emailError) {
        const params = new URLSearchParams({
          name: this.name,
          email: this.email,
          tel: this.tel,
          interest: this.interest
        });
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
      }
    },
    validateForm() {
      this.nameError = ''
      this.emailError = ''
      this.telError = '',
      this.interestError = ''

      if (!this.name) {
        this.nameError = 'Enter your name'
      }

      if (!this.email) {
        this.emailError = 'Enter your email'
      } else if (!this.isValidEmail(this.email)) {
        this.emailError = 'Error email'
      }

      if (!this.tel) {
        this.telError = 'Enter your phone number'
      }
      if (!this.interest) {
        this.interestError = 'Enter your message'
      }
    },
    isValidEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    }
  }
}
</script>

<style lang="scss">
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    align-items: center;
  }
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  &__items {
    display: flex;
    gap: 30px;
    width: 100%;
    @media (max-width: 500px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  &__field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 100%;
    position: relative;
  }
  &__error {
    position: absolute;
    bottom: -20px;
    left: 5px;
    color: red;
  }
  &__input {
    background-color: #1e1e34;
    border: 1px solid #ced4da;
    background-clip: padding-box;
    line-height: 1.5;
    appearance: none;
    border-radius: 5px;
    width: 440px;
    font-size: 17px;
    line-height: 2;
    padding-left: 5px;
    color: #40e299;
    @media (max-width: 990px) {
      width: 330px;
    }
    @media (max-width: 740px) {
      width: 300px;
    }
    @media (max-width: 700px) {
      width: 100%;
    }
    &--special {
      resize: none;  
    }
    &::placeholder {
      color: #6d6d90;
    }
    &:focus{
      outline: none;
      border-color: #40e299;
      background-color: #f4f4f4;
      color: #1e1e34;
    }
  }
  &__button.btn {
    padding: 15px 30px;
    font-size: 15px;
    font-weight: 600;
  }
}
</style>