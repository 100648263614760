<template>
    <footer class="footer">
        <div class="footer__items wrapper">
            <div class="footer__nav">
                <div class="footer__item">
                    <h3 class="footer__subtitle">
                        {{ $t('footer_item_1') }}
                    </h3>
                    <ul class="footer__submenu">
                        <li><router-link class="footer__link" to="/cryptocurrency-exchange">{{ $t('footer_item_1_1') }}</router-link></li>
                        <li><router-link class="footer__link" to="/accepting-payments-in-cryptocurrency">{{ $t('footer_item_1_2') }}</router-link></li>
                    </ul>
                </div>
                <div class="footer__item">
                    <h3 class="footer__subtitle">
                        {{ $t('footer_item_2') }}
                    </h3>
                    <ul class="footer__submenu">
                        <li><router-link class="footer__link" to="/stablex-solution">{{ $t('footer_item_2_1') }}</router-link></li>
                        <li><a class="footer__link" href="#" @click="openPdf">{{ $t('footer_item_2_2') }}</a></li> 
                    </ul>
                </div>
                <div class="footer__item">
                    <h3 class="footer__subtitle">
                        {{ $t('footer_item_3') }}
                    </h3>
                    <ul class="footer__submenu">
                        <router-link class="footer__link" to="/guide-to-crypto">{{ $t('footer_item_3_1') }}</router-link>
                    </ul>
                </div>
            </div> 
            <router-link to="/">
                <img class="footer__logo" src="../assets/images/logo/stablex1_d.svg" alt="Логотип">
            </router-link>            
            <div class="footer__rights">
                <p>Copyright © 2023. Stablex Solution.</p>
                <p>All trademarks and copyrights belong to their respective owners.</p>
            </div>
        </div>                
    </footer>
</template>

<script>
export default {
    name: 'FooterItem',
    data() {
return {
locale: 'ru',
files: {
'ru': '/docs/procedura_AML_ru.pdf',
'en': '/docs/procedura_AML_en.pdf',
'pl': '/docs/procedura_AML_en.pdf',
},
};
},
methods: {
openPdf() {
const win = window.open(this.files[this.locale], '_blank');
win.focus();
},
},
watch: {
'$i18n.locale'(newLocale) {
this.locale = newLocale;
},
},
}
</script>

<style lang="scss">
  .footer {
    padding-bottom: 80px;
    color: #1b1b30;
    @media (max-width: 700px) {
        padding-bottom: 70px;         
    }
    &__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;        
        position: relative;
        padding-top: 80px;
    }
    &__nav {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 60px;
        @media (max-width: 700px) {
            flex-direction: column;   
            gap: 30px;          
        }
    }
    &__subtitle {
        text-transform: uppercase;
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 800;
        font-family: "Gilroy-Bold", sans-serif;
    }
    &__submenu {
        list-style: none;
        & li {              
            margin-bottom: 20px;
        }
    }
    &__link {
        text-decoration: none;        
        color: #1b1b30; 
        font-size: 18px;
        font-weight: 700;
        @media (max-width: 990px) {
            font-size: 16px;
        }
        &:hover {
            color: #39cbd7;            
        }
    }
    &__logo {
        width: 160px;
        height: 60px;
    }
    &__rights {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        font-size: 17px;
        font-weight: 700;
        @media (max-width: 700px) {
            text-align: center;
        }
    }    
}
.scroll-to-top {
    position: absolute;
    top: -22px;
    right: 0;
    z-index: 99;
    width: 44px;
    height: 44px;
    background-color: #40e299;
    color: #fff;
    border-radius: 5px;
    font-size: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #39cbd7;
    }
}
</style>  