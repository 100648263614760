<template>
    <section class="exchange-info">
        <div class="exchange-info__wrapper wrapper">
            <h2 class="exchange-info__title">{{ $t('exchange_info_title') }}</h2>
            <div class="line"></div>
            <div class="exchange-info__content">
                <p class="exchange-info__text">{{ $t('exchange_info_text_1') }}</p>                         
                <p class="exchange-info__text">{{ $t('exchange_info_text_2') }}</p>
                <h3 class="exchange-info__text">{{ $t('exchange_info_text_3') }}</h3>
                <div class="exchange-info__list">
                    <div class="exchange-info__list-item">
                        <span class="icon-check exchange-info__icon"></span>
                        <p class="exchange-info__text">{{ $t('exchange_info_text_4') }}</p>
                    </div>
                    <div class="exchange-info__list-item">
                        <span class="icon-check exchange-info__icon"></span>
                        <p class="exchange-info__text">{{ $t('exchange_info_text_5') }}</p>
                    </div>
                    <div class="exchange-info__list-item">
                        <span class="icon-check exchange-info__icon"></span>
                        <p class="exchange-info__text">{{ $t('exchange_info_text_6') }}</p>
                    </div> 
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {    
        name: 'ExchangeInfoSection'
    }
</script>
<style lang="scss">
    .exchange-info {
        background-color: #0a0a21;     
        color:#fff;
        padding: 110px 0;
        @media (max-width: 990px) {
            padding-bottom: 80px;   
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 26px;               
            } 
        }
        &__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 40px;
            gap: 40px;
            width: 100%;
        }
        &__text {
            font-size: 21px;
            font-weight: 600;
            @media (max-width: 500px) {
                font-size: 17px;    
            }
        }
        &__list {
            max-width: 400px;
            @media (max-width: 990px) {
                max-width: 100%;    
            }
        }
        &__list-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 40px;
            gap: 10px;
            font-size: 21px;
            font-weight: 600;
            @media (max-width: 990px) {
                padding-bottom: 30px;  
            }
            @media (max-width: 600px) {
                font-size: 17px;             
            }
        }
        &__icon {
            color: #40e299;
        }
    }
</style>