<template>           
    <section class="about-advantages">
        <div class="about-advantages__wrapper wrapper">            
            <div class="about-advantages__content">
                <div class="about-advantages__img-wrap about-advantages__desktop">
                    <img class="about-advantages__img" src="../../../assets/images/i15.png" alt="img">
                </div> 
                <div class="about-advantages__item">
                    <h2 class="about-advantages__title">{{ $t('about_advantages_title') }}</h2>
                    <div class="line"></div>
                    <div class="about-advantages__img-wrap about-advantages__mobile">
                        <img class="about-advantages__img" src="../../../assets/images/i15.png" alt="img">
                    </div>
                    <div class="about-advantages__list">
                        <div class="about-advantages__list-item">
                            <span class="icon-check about-advantages__icon"></span>
                            <p class="about-advantages__text">{{ $t('about_advantages_text_1') }}</p>
                        </div>
                        <div class="about-advantages__list-item">
                            <span class="icon-check about-advantages__icon"></span>
                            <p class="about-advantages__text">{{ $t('about_advantages_text_2') }}</p>
                        </div>
                        <div class="about-advantages__list-item">
                            <span class="icon-check about-advantages__icon"></span>
                            <p class="about-advantages__text">{{ $t('about_advantages_text_3') }}</p>
                        </div>
                        <div class="about-advantages__list-item">
                            <span class="icon-check about-advantages__icon"></span>
                            <p class="about-advantages__text">{{ $t('about_advantages_text_4') }}</p>
                        </div>   
                    </div>
                </div> 
            </div>
        </div>
    </section> 
</template>

<script>
    export default {    
        name: 'AboutAdvantageSection'        
    }
</script>

<style lang="scss">
    .about-advantages {
        background-color:#03031c;
        color:#fff;
        padding-bottom: 80px;
        @media (max-width: 990px) {
            padding-bottom: 80px;   
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__title {
            font-size: 34px;    
            font-weight: 700;
            @media (max-width: 990px) {
                padding-top: 80px;
                text-align: center;  
            }
            @media (max-width: 600px) {
                font-size: 26px;               
            }
        }
        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 50px;
            width: 100%;
            @media (max-width: 990px) {
                flex-direction: column;   
                gap: 50px;   
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            @media (max-width: 990px) {
                align-items: center;
                width: 100%;
            }
        }
        &__list {
            max-width: 400px;
            @media (max-width: 990px) {
                max-width: 100%; 
                padding-top: 70px;   
            }
        }
        &__list-item {
            display: flex;
            align-items: flex-start;
            padding-bottom: 40px;
            gap: 10px;
            font-size: 21px;
            font-weight: 600;
            @media (max-width: 990px) {
                padding-bottom: 30px;  
            }
            @media (max-width: 600px) {
                font-size: 17px;             
            }
        }
        &__icon {
            color: #40e299;
        }
        &__img-wrap {
            width: 400px;
            height: 400px;
            position: relative;
            margin-left: 50px;
            @media (max-width: 990px) {
                margin: 0;    
            }
            @media (max-width: 600px) {
                width: 245px;
                height: 250px;             
            }
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &__desktop {
            @media (max-width: 990px) {
                display: none;
            }
        }
        &__mobile {
            @media (min-width: 991px) {
                display: none;
            }
        }
    }
</style>