import { createApp } from 'vue';
import App from './App.vue';
import "./assets/icomoon/style.css"
import VueSmoothScroll from 'vue-smooth-scroll'
import router from './router/router.js'
import i18n from './i18n/i18n.js'
import MetaInfo from 'vue-meta-info'


const app = createApp(App)
app.directive('vue-smooth-scroll', VueSmoothScroll)
app.use(VueSmoothScroll)
app.use(i18n)
app.use(router)
app.use(MetaInfo)
app.mount('#app')
