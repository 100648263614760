<template>
    <section class="payments-advantage">
        <div class="wrapper">
            <div class="payments-advantage__content">
                <h2 class="payments-advantage__title">{{ $t('payments_advantage_title') }}</h2>
                <div class="line"></div>
                <div class="payments-advantage__items">
                    <div class="payments-advantage__item">
                        <div class="payments-advantage__img-wrap">
                            <img class="payments-advantage__img" src="../../../assets/images/i8.png" alt="img">
                        </div>
                        <h3 class="payments-advantage__subtitle">{{ $t('payments_advantage_text_1') }}</h3>
                    </div>
                    <div class="payments-advantage__item">
                        <div class="payments-advantage__img-wrap">
                            <img class="payments-advantage__img" src="../../../assets/images/i9.png" alt="img">
                        </div>
                        <h3 class="payments-advantage__subtitle">{{ $t('payments_advantage_text_2') }}</h3>
                    </div>
                    <div class="payments-advantage__item">
                        <div class="payments-advantage__img-wrap">
                            <img class="payments-advantage__img" src="../../../assets/images/i10.png" alt="img">
                        </div>
                        <h3 class="payments-advantage__subtitle">{{ $t('payments_advantage_text_3') }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {    
        name: 'PaymentsAdvantageSection'
    }
</script>
<style lang="scss">
    .payments-advantage {
        background-color: #0a0a21;
        padding: 110px 0 150px;
        color: #fff;
        &__content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;            
            justify-content: center;
        }
        &__items {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 75px;
            flex-wrap: wrap;
            gap: 20px;
            @media (max-width: 768px) {
                padding-top: 45px;                
            }
            @media (max-width: 600px) {
                padding-top: 35px; 
                gap: 45px;              
            }
        }
        &__item {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 30%;
            height: 210px;
            @media (max-width: 600px) {
                flex-basis: 100%;               
            }
        }
        &__title {
            font-size: 34px;
            font-weight: 700;   
            @media (max-width: 600px) {
                font-size: 26px;               
            }         
        }
        &__subtitle {
            font-size: 24px;
            font-weight: 700;
            padding-bottom: 10px;            
            text-align: center;
            width: 100%;
        }
        &__text {
            text-align: center;
            max-width: 210px;
            font-weight: 600;
        }
        &__img-wrap {
            width: 150px;
            height: 150px;
            position: relative;
            margin-bottom: 30px;
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &__button {
            margin-top: 25px;
            font-size: 15px;
            font-weight: 600;
        }
    }
</style>