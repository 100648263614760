<template>
   <article class="main-article">
        <main-section/>
        <services-section/>
        <financial-section/>
        <info-section/>
        <advantages-section/>
        <contacts-section/>
        <scroll-button/>
   </article>
</template>
<script>
    import MainSection from './MainPageItems/MainSection.vue';
    import ServicesSection from './MainPageItems/ServicesSection.vue';
    import FinancialSection from './MainPageItems/FinancialSection.vue';
    import InfoSection from './MainPageItems/InfoSection.vue';
    import AdvantagesSection from './MainPageItems/AdvantagesSection.vue';
    import ContactsSection from '../ContactsSection.vue';
    import ScrollButton from '../ScrollButton.vue'
    export default {    
        name: 'MainPage',
        components: {
            ServicesSection,
            MainSection,
            FinancialSection,
            InfoSection,
            AdvantagesSection,
            ContactsSection,
            ScrollButton
        },
    }
</script>
<style lang="scss">
    .main-article {
        position: relative;
    }
</style>