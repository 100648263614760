import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import MainPage from '../components/MainPage/MainPage.vue'
import CryptocurrencyExchange from '../components/CryptocurrencyExchange/CryptocurrencyExchange.vue'
import AcceptPayments from '../components/AcceptPayments/AcceptPayments.vue'
import StablexSolution from '../components/StablexSolution/StablexSolution.vue'
import InformationPage from '../components/InformationPage/InformationPage.vue'
import ContactsPage from '../components/ContactsPage/ContactsPage.vue'

const router = createRouter ({
    history: createWebHistory(),
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'main',
            component: MainPage
        },
        {
            path: '/cryptocurrency-exchange',
            name: 'cryptocurrency-exchange',
            component: CryptocurrencyExchange
        },
        {
            path: '/accepting-payments-in-cryptocurrency',
            name: 'accepting-payments-in-cryptocurrency',
            component: AcceptPayments
        },
        {
            path: '/stablex-solution',
            name: 'stablex-solution',
            component: StablexSolution
        },
        {
            path: '/guide-to-crypto',
            name: 'guide-to-crypto',
            component: InformationPage
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: ContactsPage
        }
    ]
})

export default router;
const app = createApp({})
app.use(router)