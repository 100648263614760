<template>           
    <router-view/>    
</template>

<script>
    export default {    
        name: 'MainWrapper'        
    }
</script>

<style lang="scss">
</style>