<template>           
    <section class="information-crypto">
        <div class="wrapper information-crypto__wrapper">
            <div class="information-crypto__items">    
                <div class="information-crypto__item">
                    <div class="line information-crypto__line"></div>
                    <div class="information-crypto__info">
                        <div class="information-crypto__name">
                            <div class="information-crypto__img-wrap">
                                <img class="information-crypto__img" src="../../../assets/images/logo/bitcoin-btc-logo.png" alt="img">
                            </div>
                            <h3 class="information-crypto__title">Bitcoin</h3>
                            
                        </div>
                        <div class="information-crypto__text">
                            {{ $t('information_bitcoin_text_1') }} 
                        </div>
                    </div>
                    <div class="information-crypto__content">
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_2') }}</p>
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_3') }}</p>                  
                        <p class="information-crypto__text"><b>{{ $t('information_bitcoin_text_4') }}</b></p>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_bitcoin_text_5') }}</p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_bitcoin_text_6') }}</p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_bitcoin_text_7') }}</p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_bitcoin_text_8') }}</p>
                        </div>                            
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_9') }}</p>
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_10') }}</p>
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_11') }} </p>
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_12') }}</p>
                        <p class="information-crypto__text">{{ $t('information_bitcoin_text_13') }}</p>
                    </div>
                </div>
                <div class="information-crypto__item">
                    <div class="line information-crypto__line"></div>
                    <div class="information-crypto__info">
                        <div class="information-crypto__name">
                            <div class="information-crypto__img-wrap">
                                <img class="information-crypto__img" src="../../../assets/images/logo/ethereum-eth-icon.png" alt="img">
                            </div>
                            <h3 class="information-crypto__title">Ethereum</h3>                            
                        </div>
                        <div class="information-crypto__text">
                            {{ $t('information_ethereum_text_1') }}
                        </div>
                    </div>
                    <div class="information-crypto__content">
                        <p class="information-crypto__text"><b>{{ $t('information_ethereum_text_2') }}</b></p>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_ethereum_text_3') }}</p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_ethereum_text_4') }}</p>
                        </div>
                        <p class="information-crypto__text">{{ $t('information_ethereum_text_5') }}</p>
                        <p class="information-crypto__text"><b>{{ $t('information_ethereum_text_6') }}</b></p>                
                        <p class="information-crypto__text">{{ $t('information_ethereum_text_7') }}</p>
                        <p class="information-crypto__text">{{ $t('information_ethereum_text_8') }}</p>
                        <p class="information-crypto__text">{{ $t('information_ethereum_text_9') }}</p>
                        <p class="information-crypto__text">{{ $t('information_ethereum_text_10') }}</p>
                    </div>
                </div>
                <div class="information-crypto__item">
                    <div class="line information-crypto__line"></div>
                    <div class="information-crypto__info">
                        <div class="information-crypto__name">
                            <div class="information-crypto__img-wrap">
                                <img class="information-crypto__img" src="../../../assets/images/logo/tether-usdt-logo.png" alt="img">
                            </div>
                            <h3 class="information-crypto__title">Tether (USDT)</h3>    
                        </div>
                        <div class="information-crypto__text">{{ $t('information_tether_text_1') }} 
                        </div>
                    </div>
                    <div class="information-crypto__content">
                        <p class="information-crypto__text">{{ $t('information_tether_text_2') }} </p>
                        <p class="information-crypto__text">{{ $t('information_tether_text_3') }} </p>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_4') }} </p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_5') }} </p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_6') }} </p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_7') }} </p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_8') }} </p>
                        </div>
                        <div class="information-crypto__list-item">
                            <span class="icon-check information-crypto__icon"></span>
                            <p class="information-crypto__text">{{ $t('information_tether_text_9') }} </p>
                        </div>                 
                        <p class="information-crypto__text">
                            {{ $t('information_tether_text_10') }} </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {    
        name: 'InformationCryptoSection'        
    }
</script>

<style lang="scss">
    .information-crypto {
        padding: 110px 0;
        background-color: #03031c;       
        color:#fff;
        &__item {
            width: 100%;
        }
        &__line.line{
            margin: 45px auto 25px;
        }
        &__info {
            display: flex;
            gap: 45px;
            @media (max-width: 600px) {
                flex-direction: column;
            }
        }
        &__name {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
        }
        &__title {
            font-size: 25px;
            font-weight: 700;
            text-align: center;
        }
        &__img-wrap {
            width: 123px;
            height: 123px;
            position: relative;
        }
        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
        &__content {
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        &__text {
            line-height: 19px;
            font-weight: 600;
        }
        &__list-item {
            display: flex;
            align-items: flex-start;
            font-weight: 600;
        }
        &__icon {
            font-size: 19px;            
            color: #40e299;
        }
    }   
</style>